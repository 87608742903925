import styled from 'styled-components';
import { Button } from '@snsw-gel/react';

// Not reflecting Kiama? still shows underline
const StyledButtonLink = styled(Button)`
  && {
    text-decoration: none;
  }
`;

export default StyledButtonLink;
