import styled, { css } from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const notificationIconSize = 'md';

export const GridWrapper = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    grid-template-columns: auto 35% 60%;
  }
`;

export const GridContent = styled.div`
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    grid-column: 2 / span 2;
  }
`;

interface GridItemProps {
  bold?: boolean;
}

export const GridItem = styled.div<GridItemProps>`
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;

export const IconWrapper = styled.span`
  min-width: ${tokens.spacing.icons[notificationIconSize]}px;
`;

export const IconContextWrapper = styled.p`
  display: none;
`;
