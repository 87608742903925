import styled, { css } from 'styled-components';
import { tokens, IconNotificationInfo } from '@snsw-gel/react';

export const GridWrapper = styled.div`
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    display: grid;
    grid-template-columns: 40% 60%;
  }
`;

export const OdometerGridWrapper = styled.div`
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    display: grid;
    grid-template-columns: 30% 70%;
  }
`;

interface GridItemProps {
  bold?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
}

export const GridItem = styled.div<GridItemProps>`
  padding: 0rem;
  margin-bottom: 0.5rem;
  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    :nth-child(odd) {
      margin-bottom: 0rem;
    }
    :nth-child(even) {
      margin-bottom: 1rem;
    }
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: capitalize;
    `}
`;

export const IconNotificationInfoMargin = styled(IconNotificationInfo)`
  margin-left: 0.25rem;
`;

// change the width of sliding modal for mobile and tablet for table
export const MoreInfoModalMobileAdjustments = css`
  div[role='dialog'] {
    @media screen and (max-width: ${tokens.breakpoints.xl}px) {
      left: calc(20% + 48px);
      width: calc(100% - 20% - 48px);
    }
    @media screen and (max-width: ${tokens.breakpoints.md}px) {
      left: 48px;
      width: calc(100% - 48px);
    }
  }
`;

export const ModalWrapper = styled.div`
  @media screen and (max-width: ${tokens.breakpoints.md}px) {
    margin-top: 1rem;
  }
  ${MoreInfoModalMobileAdjustments}
`;

export const OdometerDetailsLearnMore = styled.span`
  ${MoreInfoModalMobileAdjustments}
`;

// Below is a copy for the layout for the Kiama <InPageAlerts /> component
// This is a fix for the component not being able to be used with the <MoreInfoModal />
export const InfoInPageAlertCopyContainer = styled.div`
  padding: 1rem;
  line-height: 1.5;
  border-left: 6px solid ${tokens.colors.borders.info};
  margin: 1rem 0rem;
  background-color: ${tokens.colors.backgrounds.info};
`;

export const InfoInPageAlertCopyLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;
