import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const EmptyFormErrorWrapper = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-top: 1rem;
`;

export const ErrorIconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  margin-top: 0.25rem;
`;

export const ErrorText = styled.span`
  color: ${tokens.colors.text.error};
`;
