import React from 'react';
import * as Styled from './GoogleReCaptchaDisclaimer.styles';
import './GoogleReCaptchaDisclaimer.css';

const GoogleReCaptchaDisclaimer: React.FC = () => {
  return (
    <Styled.ContentContainer>
      <Styled.DisclaimerText>
        This site is protected by reCAPTCHA and the Google{' '}
        <Styled.ExternalAnchor
          href='https://policies.google.com/privacy'
          rel='noopener noreferrer'
          target='_blank'
        >
          Privacy Policy
        </Styled.ExternalAnchor>{' '}
        and{' '}
        <Styled.ExternalAnchor
          href='https://policies.google.com/terms'
          rel='noopener noreferrer'
          target='_blank'
        >
          Terms of Service
        </Styled.ExternalAnchor>{' '}
        apply.
      </Styled.DisclaimerText>
    </Styled.ContentContainer>
  );
};

export default GoogleReCaptchaDisclaimer;
