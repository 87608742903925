import styled from 'styled-components';
import { Heading } from '@snsw-gel/react';

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  margin: 2rem 0;
`;

export const MarkdownWrapper = styled.div`
  flex-grow: 1;
`;

export const StyledHeading = styled(Heading)`
  && {
    margin-top: 0.25rem;
  }
`;
