import styled from 'styled-components';

export const TableHeadCellStyling = styled.th`
  font-weight: 700 !important;
`;

export const TableRowStyling = styled.tr`
  & > td {
    line-height: 1.5rem;
  }
  & > td:first-child {
    font-weight: 500;
  }
`;
