import React from 'react';
import ReactMarkdown from 'react-markdown';
import { InPageAlert } from '@snsw-gel/react';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import downloadErrorMessage from './downloadErrorMessage.md';
import printErrorMessage from './printErrorMessage.md';

const FailedDownloadPrintReportError: React.FC<
  FailedDownloadPrintReportErrorProps
> = ({ action }) => {
  return (
    <InPageAlert variant='error' title={alertTitles[action]} role='alert'>
      <ReactMarkdown components={notificationLinks}>
        {alertMessages[action]}
      </ReactMarkdown>
    </InPageAlert>
  );
};

export default FailedDownloadPrintReportError;

interface FailedDownloadPrintReportErrorProps {
  action: 'download' | 'print';
}

const alertTitles = {
  download: 'Download failed',
  print: 'Print failed',
};

const alertMessages = {
  download: downloadErrorMessage,
  print: printErrorMessage,
};
