import React from 'react';
import { SectionPage } from '@snsw-gel/react';
import { systemFailureError } from 'config/errorMessages';
import Header, {
  genericTransactionErrorHeading,
} from 'components/Header/Header';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';

const ErrorBoundaryPage: React.FC = () => {
  return (
    <>
      <Header headingTitle={genericTransactionErrorHeading} />
      <PageContentWrapper stepName='Error'>
        <SectionPage>
          <NotificationMessage message={systemFailureError} />
        </SectionPage>
      </PageContentWrapper>
    </>
  );
};

export default ErrorBoundaryPage;
