import React from 'react';
import ReactMarkdown from 'react-markdown';
import { SectionPage } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import { StyledInPageAlert } from './ConditionalNotification.styled';
import conditionalInfoMarkdown from './conditionalInfo.md';

const ConditionalNotification: React.FC = () => {
  const isConditionalVehicle = useStoreSelector(
    (state) => state.vehicle.isConditionalVehicle
  );

  if (!isConditionalVehicle) return <></>;

  return (
    <SectionPage>
      <StyledInPageAlert
        variant='info'
        title='This vehicle is conditionally registered'
      >
        <ReactMarkdown components={notificationLinks}>
          {conditionalInfoMarkdown}
        </ReactMarkdown>
      </StyledInPageAlert>
    </SectionPage>
  );
};

export default ConditionalNotification;
