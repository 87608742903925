import React from 'react';
import {
  SkeletonContainer,
  SkeletonText,
  Button,
} from '@snsw-gel/react';

const ConfirmationDescriptionSkeleton: React.FC = () => (
  <SkeletonContainer>
    <SkeletonText />
    <SkeletonText />
    <Button disabled>Loading...</Button>
  </SkeletonContainer>
);

export default ConfirmationDescriptionSkeleton;
