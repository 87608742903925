import React from 'react';
import { SectionPage, TextLink, Heading } from '@snsw-gel/react';

const TransactionLinks: React.FC = () => {
  return (
    <SectionPage>
      <Heading level={2}>You may also like</Heading>
      <ul>
        {transactionLinkData.map(({ label, href }) => (
          <li key={label}>
            <TextLink href={href}>{label}</TextLink>
          </li>
        ))}
      </ul>
    </SectionPage>
  );
};

export default TransactionLinks;

interface TransactionData {
  label: string;
  href: string;
}

const transactionLinkData: TransactionData[] = [
  {
    label: 'Transfer a vehicle registration',
    href: 'https://www.service.nsw.gov.au/transaction/transfer-a-vehicle-registration',
  },
  {
    label: 'Renew a vehicle registration',
    href: 'https://www.service.nsw.gov.au/transaction/renew-a-vehicle-registration',
  },
  {
    label: 'Change vehicle registration details',
    href: 'https://www.service.nsw.gov.au/transaction/change-details-your-vehicle',
  },
];
