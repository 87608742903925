import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { TextLink } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import { stepFRCReviewPage, stepVHCReviewPage } from 'config/steps';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import { swapTransactionType } from 'utils/route/swapTransactionType';
import {
  RoutePathParams,
  TransactionTypeParams,
  transactionTypes,
} from 'utils/route/urlParams';
import TransactionSwitchSkeleton from './TransactionSwitchSkeleton';
import * as Styled from './TransactionSwitch.styled';

const TransactionSwitch: React.FC<TransactionSwitchProps> = ({
  skeleton = false,
}) => {
  const isConditionalVehicle = useStoreSelector(
    (state) => state.vehicle.isConditionalVehicle
  );
  const { transactionType } = useParams<RoutePathParams>();

  if (
    transactionType === undefined ||
    !isValidTransactionType(transactionType) ||
    skeleton
  )
    return <TransactionSwitchSkeleton />;

  const transactionLinkLabels = findVehicleLinkLabels[transactionType];

  const handleSwitchTransactionLink = () => {
    const newTransactionType = swapTransactionType(transactionType);
    const transactionName = transactionTypes[newTransactionType];
    const stepName =
      newTransactionType === 'vhc' ? stepVHCReviewPage : stepFRCReviewPage;
    document.title = `Service NSW - ${transactionName} - ${stepName}`;
  };

  return (
    <>
      <Styled.LinkContainer>
        {!isConditionalVehicle && (
          <div>
            <TextLink
              as={Link}
              href={`/${swapTransactionType(transactionType)}/review`}
              onClick={() => handleSwitchTransactionLink()}
            >
              {swapTransactionLinkLabels[transactionType]}
            </TextLink>
          </div>
        )}
        <div>
          <TextLink as={Link} href={`/${transactionType}`}>
            {transactionLinkLabels}
          </TextLink>
        </div>
      </Styled.LinkContainer>
    </>
  );
};

export default TransactionSwitch;

interface TransactionSwitchProps {
  skeleton?: boolean;
}

const swapTransactionLinkLabels = {
  vhc: 'Free registration check',
  frc: 'Buy a report',
};

const findVehicleLinkLabels: Record<TransactionTypeParams, string> = {
  frc: 'Check another registration',
  vhc: 'Buy another report',
};
