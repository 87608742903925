import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const VehicleDetailsLayout = styled.div`
  display: grid;
  grid-template-columns: auto;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-template-columns: 1fr auto;
  }
`;

export const VehicleDetailsContainer = styled.div`
  grid-row-start: 1;
  grid-column-start: 1;
`;

export const TransactionSwitchContainer = styled.div`
  grid-row-start: 3;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-row-start: 1;
    grid-column-start: 2;
    margin-top: auto;
  }
`;

export const TimestampContainer = styled.div`
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    grid-column-start: span 2;
  }
`;
