import { VehicleDetails } from 'api/types/vehicle';
import { useStoreSelector } from 'store';

const getSelectedVehicleDetails = (): VehicleDetails | null => {
  return useStoreSelector((store) => {
    const { vehicleDetails, selectedVehicle, isConditionalVehicle } =
      store.vehicle;

    if (isConditionalVehicle && vehicleDetails) {
      return vehicleDetails[0];
    }

    if (selectedVehicle === null || vehicleDetails === null) {
      return null;
    }

    const { plateNumber, plateType } = selectedVehicle;
    const vehicle = vehicleDetails.find(
      (vehicle) =>
        vehicle.plate.plateNumber === plateNumber &&
        vehicle.plate.plateType === plateType
    );
    return vehicle || null;
  });
};

export default getSelectedVehicleDetails;
