import { VehicleDetails } from 'api/types/vehicle';

const isHeavyVehicle = (vehicle: VehicleDetails): boolean => {
  return (
    vehicle &&
    vehicle.configurationDetails !== null &&
    vehicle.grossVehicleMass !== null &&
    vehicle.grossVehicleMass !== undefined &&
    parseInt(vehicle.grossVehicleMass) > 4500
  );
};

export default isHeavyVehicle;
