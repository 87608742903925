import React from 'react';
import {
  BaseEntityType,
  ConditionsType,
  RegistrationCondition,
} from 'api/types/vehicle';
import {
  ConditionCodes,
  codeDescriptionsToReplaceWithRegex,
  conditionCodesWithDescriptions,
  vehicleConditionsModifiedCode,
} from 'config/conditionCodes';
import * as Styled from './ConditionCode.styled';

const ConditionCode: React.FC<ConditionsType> = ({
  registrationCondition,
  conditionalRegistration,
}) => {
  const hasConditionalRegistration =
    conditionalRegistration?.conditionCode &&
    conditionalRegistration?.conditionCode.length > 0;

  const hasRegistrationCondition =
    registrationCondition && registrationCondition.length > 0;

  const conditions: (BaseEntityType | RegistrationCondition)[] = [];

  if (hasConditionalRegistration) {
    conditionalRegistration?.conditionCode?.forEach((condition) => {
      conditions.push(condition);
    });
  }

  if (hasRegistrationCondition) {
    registrationCondition?.forEach(({ codeCategory, code, description }) => {
      const combinedCode = checkAndModifyConditionCode(codeCategory + code);
      conditions.push({
        code: combinedCode,
        description: description,
      });
    });
  }

  if (conditions.length < 1) return <div>None</div>;

  return (
    <Styled.ConditionCodeWrapper>
      {conditions.map(({ code, description }) => {
        const desc = formatUIDescription(code, description);
        return (
          <div key={code}>
            {code} {desc}
          </div>
        );
      })}
    </Styled.ConditionCodeWrapper>
  );
};

export default ConditionCode;

export const formatUIDescription = (
  code: string,
  description: string
): string => {
  const codeNotFound = !(code in conditionCodesWithDescriptions);
  if (codeNotFound) {
    return description;
  }

  const caseSensitiveDescription =
    conditionCodesWithDescriptions[code as ConditionCodes];
  if (caseSensitiveDescription !== null) {
    return caseSensitiveDescription;
  }

  if (code in codeDescriptionsToReplaceWithRegex) {
    const { text, regex } =
      codeDescriptionsToReplaceWithRegex[
        code as keyof typeof codeDescriptionsToReplaceWithRegex
      ];
    return description.replace(regex, text);
  }

  // fallback: use DRIVES description
  return description;
};

// convert V1 => V01
export const checkAndModifyConditionCode = (code: string): string => {
  if (code in vehicleConditionsModifiedCode) {
    return vehicleConditionsModifiedCode[
      code as keyof typeof vehicleConditionsModifiedCode
    ];
  }
  return code;
};
