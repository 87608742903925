import React from 'react';
import { useHistory } from 'react-router-dom';
import { SectionPage, Button, BrandIconReportsDocs } from '@snsw-gel/react';
import { detectIOS } from '@rmstransactions/components';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import { getSelectedVHCValidationResponse } from 'utils/providers/getSelectedVHCValidation';
import isConditionalVehicle from 'utils/validation/isConditionalVehicle';
import * as Styled from './BuyAReportPanel.styled';

const { isDeviceIOSSafari } = detectIOS;

const BuyAReportPanel: React.FC = () => {
  const vehicleDetails = getSelectedVehicleDetails();
  const vhcValidation = getSelectedVHCValidationResponse();
  const history = useHistory();

  const reportCost =
    vhcValidation?.vhcPrice?.grossAmount ?? vehicleDetails?.vhcReportCost;
  const vhcReportHeading = `Buy a vehicle history report for $${reportCost}`;

  return (
    <>
      {vehicleDetails && !isConditionalVehicle(vehicleDetails) && (
        <SectionPage data-testid='buy-a-report-panel'>
          <Styled.CardContainer
            id='buy-a-report-panel'
            data-testid='buy-a-report-panel'
            border
          >
            <Styled.MainContentWrapper>
              <BrandIconReportsDocs />
              <Styled.HeadingDetails level={3}>
                {vhcReportHeading}
              </Styled.HeadingDetails>
              <Styled.DescriptionDetails>
                <Styled.ParagraphDetails>
                  The report provides important information about a vehicle
                  history. This includes if the vehicle has been written-off or
                  stolen.
                </Styled.ParagraphDetails>
                <Styled.ButtonContainer>
                  <Button
                    onClick={() => {
                      history.push('/vhc/review');
                    }}
                  >
                    Buy a report
                  </Button>
                  {isDeviceIOSSafari() ? (
                    <a
                      href='/VehicleHistoryReportSample.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Download sample report
                    </a>
                  ) : (
                    <a href='/VehicleHistoryReportSample.pdf' download>
                      Download sample report
                    </a>
                  )}
                </Styled.ButtonContainer>
              </Styled.DescriptionDetails>
            </Styled.MainContentWrapper>
          </Styled.CardContainer>
        </SectionPage>
      )}
    </>
  );
};

export default BuyAReportPanel;
