import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { UserToken, HttpRequestHandler } from '@rmstransactions/components';

HttpRequestHandler.setCommonApiKeyHeader();

const baseApiUrl = process.env.REACT_APP_API_HOST;

const preRequestHandler = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  config.headers.setContentType('application/json');

  const transactionToken = UserToken.getValue();
  if (transactionToken) {
    config.headers['X-Token'] = transactionToken;
  }

  return config;
};

const errorRequestHandler = (error: AxiosError): Promise<AxiosError> => {
  return Promise.resolve(error);
};

const successResponseHandler = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const errorResponseHandler = (error: AxiosError): AxiosError => {
  return error;
};

const HttpClient = axios.create({
  baseURL: baseApiUrl,
  withCredentials: true,
});

HttpClient?.interceptors?.request?.use(preRequestHandler, errorRequestHandler);
HttpClient?.interceptors?.response?.use(
  successResponseHandler,
  errorResponseHandler
);

export default HttpClient;

export const handleResponseData = <T>(
  response: AxiosResponse<T> | AxiosError
): T | null => {
  if ('data' in response && !('isAxiosError' in response)) {
    return response.data;
  }
  return null;
};

export const handleResponseHeaders = (
  response: AxiosResponse | AxiosError
): Record<string, any> | null => {
  if ('headers' in response && !('isAxiosError' in response)) {
    return response.headers;
  } else {
    return null;
  }
};
