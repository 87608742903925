import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SectionPage } from '@snsw-gel/react';
import { useStoreDispatch, useStoreSelector } from 'store';
import { vehicleSliceActions } from 'store/slice/vehicle';
import { getVehicleDetails } from 'api/api';
import { Plate } from 'api/types/vehicle';
import { DriveMessageDetail } from 'api/types/drives';
import { transactionErrorKey } from 'config/constants';
import VehicleDetailsCard from 'components/VehicleDetailsCard/VehicleDetailsCard';
import ActionSelectVehicle from 'components/VehicleDetailsCard/ActionSelectVehicle/ActionSelectVehicle';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { handleResponseData } from 'utils/api/httpClient';
import { errorPath, RoutePathParams } from 'utils/route/urlParams';
import getUnsuccessfulMessages from 'utils/validation/getUnsuccessfulMessages';
import MultipleVehiclesOptionsSkeleton from './MultipleVehiclesOptionsSkeleton';
import * as Styled from './MultipleVehiclesOptions.styled';

const MultipleVehiclesOptions: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const { transactionType } = useParams<RoutePathParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<
    Record<string, DriveMessageDetail>
  >({});
  const vehicleDetails = useStoreSelector(
    (state) => state.vehicle.vehicleDetails
  );
  if (vehicleDetails === null) return <MultipleVehiclesOptionsSkeleton />;

  const handleSelectVehicle = async (plate: Plate) => {
    setIsLoading(true);
    if (transactionType === 'vhc') {
      const { plateNumber, plateType } = plate;
      if (!plateType) {
        history.push(errorPath);
        return;
      }

      const response = await getVehicleDetails(plateNumber, plateType);
      const data = handleResponseData(response);
      if (data === null) {
        history.push(errorPath, { [transactionErrorKey]: transactionType });
        return;
      }

      const { validationSystemMessages } = data;
      if (validationSystemMessages) {
        const error = getUnsuccessfulMessages(validationSystemMessages);
        if (error) {
          setErrorMessages((prev) => {
            return { ...prev, [plateType]: error };
          });
          setIsLoading(false);
          return;
        }
      }

      dispatch(vehicleSliceActions.setVehicleDetails(data));
    } else {
      dispatch(vehicleSliceActions.setSelectedVehicle(plate));
    }

    const redirectUrl = `/${transactionType}/review`;
    history.push(redirectUrl);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <SectionPage data-testid='multiple-vehicle-section'>
        <Styled.VehicleListContainer>
          {vehicleDetails.map((vehicleData) => {
            const { plateNumber, plateType } = vehicleData.plate;
            const plateTypeErrorMessage =
              plateType && plateType in errorMessages
                ? errorMessages[plateType]
                : null;

            const selectButton = (
              <ActionSelectVehicle
                label='Select'
                onClickEvent={() => handleSelectVehicle(vehicleData.plate)}
              />
            );

            return (
              <VehicleDetailsCard
                key={`${plateNumber}-${plateType}`}
                vehicleDetails={vehicleData}
                border
                actionComponent={!plateTypeErrorMessage ? selectButton : <></>}
              >
                <NotificationMessage message={plateTypeErrorMessage} />
              </VehicleDetailsCard>
            );
          })}
        </Styled.VehicleListContainer>
      </SectionPage>
    </>
  );
};

export default MultipleVehiclesOptions;
