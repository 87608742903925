import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, SectionPage, Heading } from '@snsw-gel/react';
import { pdfActions } from '@rmstransactions/components';
import { useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import { vhcReportFileName } from 'config/constants';
import { emailReceiptActionID } from 'pages/ConfirmationPage/ReceiptActions/ReceiptActions';
import NoEmailWarning from './NoEmailWarning/NoEmailWarning';
import ConfirmationDescriptionSkeleton from './ConfirmationDescriptionSkeleton';
import FailedDownloadReportError from './FailedDownloadReportError/FailedDownloadReportError';
import * as Styled from './ConfirmationDescription.styled';

const ConfirmationDescription: React.FC = () => {
  const dispatch = useDispatch();
  const [hasErrorDownload, setHasErrorDownload] = useState(false);
  const confirmationData = useStoreSelector((state) => state.confirmation.data);

  const handleDownloadReport = () => {
    if (!confirmationData?.vhcPDFBase64) {
      setHasErrorDownload(true);
      return;
    }

    try {
      pdfActions.downloadBase64PDF(
        confirmationData.vhcPDFBase64,
        vhcReportFileName
      );
      dispatch(confirmationSliceActions.setHasReceivedReport(true));
      setHasErrorDownload(false);
    } catch {
      setHasErrorDownload(true);
    }
  };

  return (
    <SectionPage>
      <NoEmailWarning />
      <Heading level={2}>Download your vehicle report</Heading>
      {confirmationData ? (
        <>
          <p>
            You have successfully purchased the vehicle history report for{' '}
            <strong>{confirmationData.plateNumber}</strong>.{' '}
            {confirmationData.emailAddress && (
              <>
                The vehicle history report and tax invoice has been emailed to{' '}
                <strong>{confirmationData.emailAddress}</strong>.{' '}
                <a href={`#${emailReceiptActionID}`}>
                  Send it to another email
                </a>
              </>
            )}
          </p>
          <Styled.ButtonContainer>
            <Button onClick={handleDownloadReport}>
              Download vehicle history report
            </Button>
          </Styled.ButtonContainer>
          {hasErrorDownload && <FailedDownloadReportError />}
        </>
      ) : (
        <ConfirmationDescriptionSkeleton />
      )}
    </SectionPage>
  );
};

export default ConfirmationDescription;
