import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@snsw-gel/react';
import {
  validatePlateNumber,
  plateNumberValidationData,
  PlateNumberValidationErrors,
  ChallengeReCaptcha,
  ChallengeReCaptchaHelpers,
  GoogleReCaptchaHelpers,
} from '@rmstransactions/components';
import { useStoreDispatch } from 'store';
import { vehicleSliceActions } from 'store/slice/vehicle';
import { getVehicleList } from 'api/api';
import { DriveMessageDetail } from 'api/types/drives';
import { vhcConditionalVehicleError } from 'config/errorMessages';
import { transactionErrorKey } from 'config/constants';
import { stepFindVehiclePage } from 'config/steps';
import PlateNumberInput from 'components/PlateNumberInput/PlateNumberInput';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { handleResponseData } from 'utils/api/httpClient';
import isConditionalVehicle from 'utils/validation/isConditionalVehicle';
import {
  errorPath,
  RoutePathParams,
  transactionTypes,
  TransactionTypeParams,
} from 'utils/route/urlParams';
import { swapTransactionType } from 'utils/route/swapTransactionType';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import getUnsuccessfulMessages from 'utils/validation/getUnsuccessfulMessages';
import TermsAndConditionsModal from './TermsAndConditionsModal/TermsAndConditionsModal';
import * as Styled from './PlateNumberForm.styled';

const { isErrorLowRecaptchaScore } = GoogleReCaptchaHelpers;
const { recaptchaChallengeNotificationError, getTokenUsingContainerID } =
  ChallengeReCaptchaHelpers;

const PlateNumberForm: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const { transactionType } = useParams<RoutePathParams>();
  const [plateNumber, setPlateNumber] = useState<string>('');
  const [checkedTermsAndCondition, setCheckedTermsAndCondition] =
    useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] =
    useState<DriveMessageDetail | null>(null);

  const [invalidPlateError, setInvalidPlateError] =
    useState<PlateNumberValidationErrors | null>(null);
  const [isInvalidTermsAndConditions, setIsInvalidTermsAndConditions] =
    useState<boolean>(false);

  const [hasSilentRecaptchaError, setHasSilentRecaptchaError] =
    useState<boolean>(false);
  const [isChallengeRecaptchaNotSet, setIsChallengeRecaptchaNotSet] =
    useState<boolean>(false);

  const validTransactionType = isValidTransactionType(transactionType);
  if (!validTransactionType || transactionType === undefined) return <></>;

  const checkFormValidation = () => {
    const plateNumberError = validatePlateNumber(plateNumber);
    const challengeRecaptchaError =
      hasSilentRecaptchaError &&
      !getTokenUsingContainerID(challengeIdReCaptcha);

    const hasValidationError =
      plateNumberError || !checkedTermsAndCondition || challengeRecaptchaError;

    if (plateNumberError) {
      setInvalidPlateError(plateNumberError);
    }
    if (!checkedTermsAndCondition) {
      setIsInvalidTermsAndConditions(!checkedTermsAndCondition);
    }
    if (challengeRecaptchaError) {
      setIsChallengeRecaptchaNotSet(true);
    }

    if (hasValidationError) {
      return false;
    }
    return true;
  };

  const handleSubmitForm = async (switchTransactionType = false) => {
    const result = checkFormValidation();
    if (!result) return;

    const targetedTransactionType = switchTransactionType
      ? swapTransactionType(transactionType)
      : transactionType;

    setIsLoading(true);
    const buyVHC = targetedTransactionType === 'vhc';
    const response = await getVehicleList(
      plateNumber,
      buyVHC,
      hasSilentRecaptchaError ? challengeIdReCaptcha : null
    );
    const data = handleResponseData(response);
    if (data === null) {
      if (!isChallengeRecaptchaNotSet && isErrorLowRecaptchaScore(response)) {
        setIsLoading(false);
        setHasSilentRecaptchaError(true);
        setNotificationMessage(recaptchaChallengeNotificationError);
        return;
      }

      history.push(errorPath, {
        [transactionErrorKey]: targetedTransactionType,
      });
      return;
    }

    setIsChallengeRecaptchaNotSet(false);

    const { vehicleDetails, messages } = data;
    const error = getUnsuccessfulMessages(messages);
    if (error) {
      // set error/info notification
      setNotificationMessage(error);
      setIsLoading(false);
      return;
    }
    if (!vehicleDetails) {
      // unexpected vehicleDetails is null/undefined
      history.push(errorPath, {
        [transactionErrorKey]: targetedTransactionType,
      });
      return;
    }

    if (
      isConditionalVehicle(vehicleDetails[0]) &&
      targetedTransactionType === 'vhc'
    ) {
      // VHC notification for a conditional vehicle
      setNotificationMessage(vhcConditionalVehicleError);
      setIsLoading(false);
      return;
    }

    dispatch(vehicleSliceActions.setCheckRegistrationData(data));

    const isMultipleVehicles = vehicleDetails.length > 1;
    const redirectionStagePath = isMultipleVehicles ? 'vehicles' : 'review';
    const redirectUrl = `/${targetedTransactionType}/${redirectionStagePath}`;
    history.push(redirectUrl);
  };

  const handleAlternateSubmit = () => {
    const isEmptyFormSwapTransaction = plateNumber === '';
    if (isEmptyFormSwapTransaction) {
      setInvalidPlateError(null);
      setIsInvalidTermsAndConditions(false);

      const alternateTransactionType = swapTransactionType(transactionType);
      const redirectUrl = `/${alternateTransactionType}`;
      document.title = `Service NSW - ${transactionTypes[alternateTransactionType]} - ${stepFindVehiclePage}`;
      history.push(redirectUrl);
      return;
    }

    handleSubmitForm(true);
  };

  const handleModalOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleModalAccept = () => {
    setCheckedTermsAndCondition(true);
    setModalOpen(false);
  };

  const handleUpdatePlateNumber = (newPlateNumber: string) => {
    setPlateNumber(newPlateNumber);
    if (invalidPlateError) {
      setInvalidPlateError(null);
    }
  };

  const handleCheckedTermsAndCondition = () => {
    setCheckedTermsAndCondition((prev) => !prev);
    if (isInvalidTermsAndConditions) {
      setIsInvalidTermsAndConditions(false);
    }
  };

  return (
    <>
      <NotificationMessage message={notificationMessage} marginBottom />
      <Styled.FormWrapper>
        <Styled.PlateNumberContainer>
          <label htmlFor='plateNumberInput'>
            <Styled.FormHeading id={plateNumberInputAriaLabel} level={2}>
              Search for a NSW vehicle
            </Styled.FormHeading>
          </label>
          <PlateNumberInput
            value={plateNumber}
            updatePlateNumber={handleUpdatePlateNumber}
            errorMessage={
              invalidPlateError ? formErrorData[invalidPlateError] : undefined
            }
            helpMessage='Enter a NSW number plate, for example ABC123.'
            ariaLablledBy={plateNumberInputAriaLabel}
            required
          />
        </Styled.PlateNumberContainer>
        <div>
          <Styled.TermsDescription>
            Please read and accept the{' '}
            <Button variant='link' onClick={handleModalOpen}>
              Terms and Conditions
            </Button>{' '}
            before proceeding.
          </Styled.TermsDescription>
          <Styled.FormCheckbox
            id='termsAndConditions'
            data-testid='testsAndConditionsCheckbox'
            name='accept terms and conditions'
            label='I accept the Terms and Conditions'
            value='accept'
            onChange={handleCheckedTermsAndCondition}
            checked={checkedTermsAndCondition}
            hasError={isInvalidTermsAndConditions}
            errorMessage={formErrorData['TERMS_ERROR']}
            isRequired
          />
        </div>

        <ChallengeReCaptcha
          containerID={challengeIdReCaptcha}
          render={hasSilentRecaptchaError}
          showNoInputError={isChallengeRecaptchaNotSet}
        />

        <Styled.ButtonContainer>
          <Button onClick={() => handleSubmitForm()}>
            {primaryButtonLabel[transactionType]}
          </Button>
          {transactionType && (
            <Button onClick={() => handleAlternateSubmit()} variant='secondary'>
              {secondaryButtonLabel[transactionType]}
            </Button>
          )}
        </Styled.ButtonContainer>
      </Styled.FormWrapper>
      <TermsAndConditionsModal
        isOpen={modalOpen}
        acceptFunction={() => handleModalAccept()}
        closeFunction={() => setModalOpen(false)}
      />
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default PlateNumberForm;

type FormValidationErrors = PlateNumberValidationErrors | 'TERMS_ERROR';

const formErrorData: {
  [key in FormValidationErrors]: string;
} = {
  ...plateNumberValidationData,
  TERMS_ERROR: 'Please accept the Terms and Conditions',
};

const primaryButtonLabel: { [key in TransactionTypeParams]: string } = {
  frc: 'Check registration',
  vhc: 'Buy a report',
};

const secondaryButtonLabel: { [key in TransactionTypeParams]: string } = {
  frc: 'Buy a vehicle history report',
  vhc: 'Free registration check',
};

const plateNumberInputAriaLabel = 'headingPlateInput';

const challengeIdReCaptcha = 'recaptcha-challenge-component';
