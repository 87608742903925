/**
 * removes periods "." from a string as requested by designers
 * should be used for the vehicleDetails.vehicleModel.manufacturer.description
 * @param description the vehicle models manufacturer description
 * @returns adjusted string
 */
export const formatVehicleMakeDescription = (description: string): string => {
  // string.prototype.replaceAll is not a function, JS version error
  const stringArray = description.split('').filter((char) => char !== '.');
  return stringArray.join('');
};
