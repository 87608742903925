import React, { useEffect } from 'react';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { RoutePathParams, transactionTypes } from 'utils/route/urlParams';

const SetDocumentTitle: React.FC<SetDocumentTitleProps> = ({ stepName }) => {
  const { transactionType } = useParams<RoutePathParams>();
  const location = useLocation();

  useEffect(() => {
    let transaction = 'Check vehicle registration';
    if (transactionType && transactionType in transactionTypes) {
      transaction = transactionTypes[transactionType];
    }

    // confirmation page, check both paths
    const isViewReceiptURL = matchPath(location.pathname, {
      path: '/view-receipt/:receiptUUID',
    });
    if (location.pathname === '/confirmation' || isViewReceiptURL) {
      transaction = transactionTypes['vhc'];
    }

    document.title = `Service NSW - ${transaction} - ${stepName}`;
  }, [stepName]);

  return null;
};

export default SetDocumentTitle;

interface SetDocumentTitleProps {
  stepName: string;
}
