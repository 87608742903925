import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriveMessageDetail } from 'api/types/drives';
import {
  CheckRegistrationVehicleListResponse,
  VHCValidationResponseDetails,
} from 'api/types/response';
import { Plate, VehicleDetails } from 'api/types/vehicle';
import vhcVehicleSession, {
  VehicleSessionData,
} from 'utils/session/vhcVehicleData';
import isConditionalVehicle from 'utils/validation/isConditionalVehicle';

export interface VehicleState {
  vehicleDetails: VehicleDetails[] | null;
  vhcValidations: Array<VHCValidationResponseDetails | null> | null;
  messages: DriveMessageDetail[] | null;
  selectedVehicle: Plate | null;
  isConditionalVehicle: boolean;
}

export const initialState: VehicleState = {
  vehicleDetails: null,
  vhcValidations: null,
  messages: null,
  selectedVehicle: null,
  isConditionalVehicle: false,
};

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setCheckRegistrationData: (
      state,
      action: PayloadAction<CheckRegistrationVehicleListResponse>
    ) => {
      const { vehicleDetails, vhcValidations, messages } = action.payload;
      if (!vehicleDetails) {
        throw new Error('cannot find vehicle details');
      }
      state.vehicleDetails = vehicleDetails;
      state.messages = messages;
      state.vhcValidations =
        vhcValidations || new Array(vehicleDetails.length).fill(null);

      state.isConditionalVehicle = isConditionalVehicle(vehicleDetails[0]);
      const isSingleVehicle = vehicleDetails.length === 1;
      state.selectedVehicle = isSingleVehicle ? vehicleDetails[0].plate : null;
    },
    setVehicleDetails: (
      state,
      action: PayloadAction<VHCValidationResponseDetails>
    ) => {
      if (!state.vehicleDetails || !state.vhcValidations) {
        throw new Error('cannot find existing vehicle details');
      }

      const vhcValidationResponse = action.payload;
      const validationData = vhcValidationResponse.validationResponse;
      const validationMessages = vhcValidationResponse.validationSystemMessages;
      if (!validationData && !validationMessages) {
        throw new Error('cannot find valid response value');
      }

      let plateNumber, plateType;
      if (
        validationData?.plate?.plateNumber &&
        validationData?.plate?.plateType
      ) {
        plateNumber = validationData.plate.plateNumber;
        plateType = validationData.plate.plateType;
      } else {
        if (
          !state.selectedVehicle?.plateNumber ||
          !state.selectedVehicle?.plateType
        ) {
          throw new Error('vehicle has not been selected');
        }
        plateNumber = state.selectedVehicle.plateNumber;
        plateType = state.selectedVehicle.plateType;
        vhcValidationResponse.validationResponse = {
          plate: {
            plateNumber,
            plateType,
          },
          vhcPrice: null,
        };
      }

      const types = state.vehicleDetails.map(
        (vehicle) => `${vehicle.plate.plateNumber}-${vehicle.plate.plateType}`
      );
      const index = types.indexOf(`${plateNumber}-${plateType}`);
      if (index < 0) {
        throw new Error('cannot find selected vehicle');
      }

      state.selectedVehicle = {
        plateNumber,
        plateType,
      };
      state.vhcValidations[index] = vhcValidationResponse;
    },
    setSelectedVehicle: (state, action: PayloadAction<Plate>) => {
      state.selectedVehicle = action.payload;
    },
    resetVehicleData: () => initialState,
    saveVehicleStoreToSessionStorage: (state) => {
      const {
        vehicleDetails,
        vhcValidations,
        messages,
        selectedVehicle,
        isConditionalVehicle,
      } = state;
      const missingVehicleData =
        vehicleDetails === null ||
        vhcValidations === null ||
        messages === null ||
        selectedVehicle === null;
      if (missingVehicleData) {
        throw new Error('vehicle data cannot be set');
      }

      const vehicleData = {
        vehicleDetails: vehicleDetails,
        vhcValidations: vhcValidations,
        messages: messages,
        selectedVehicle: selectedVehicle,
        isConditionalVehicle: isConditionalVehicle,
      } as VehicleSessionData;
      vhcVehicleSession.setData(vehicleData);
    },
    loadVehicleStoreFromSessionStorage: (state) => {
      if (!vhcVehicleSession.hasData()) {
        throw new Error('cannot retrieve vehicle data');
      }
      const vehicleData = vhcVehicleSession.getData();
      const {
        vehicleDetails,
        vhcValidations,
        messages,
        selectedVehicle,
        isConditionalVehicle,
      } = vehicleData;

      const isInvalid =
        !vehicleDetails ||
        !vhcValidations ||
        !messages ||
        !selectedVehicle ||
        typeof isConditionalVehicle !== 'boolean';
      if (isInvalid) {
        throw new Error('cannot set retreived vehicle data');
      }

      state.vehicleDetails = vehicleDetails;
      state.vhcValidations = vhcValidations;
      state.messages = messages;
      state.selectedVehicle = selectedVehicle;
      state.isConditionalVehicle = isConditionalVehicle;

      vhcVehicleSession.clearData();
    },
  },
});

export const vehicleSliceActions = vehicleSlice.actions;
export default vehicleSlice.reducer;
