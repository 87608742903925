import React from 'react';
import { SectionPage, Heading } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import PaymentSummarySkeleton from './PaymentSummarySkeleton';
import * as Styled from './PaymentSummary.styled';

const PaymentSummary: React.FC = () => {
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  if (!confirmationData) return <PaymentSummarySkeleton />;

  const { receiptNumber, transactionDate, amountPaid } = confirmationData;

  return (
    <SectionPage>
      <Styled.SummaryWrapper>
        {PaymentSummaryHeading}
        <Styled.ReceptDetails>
          Receipt number: {receiptNumber}
        </Styled.ReceptDetails>
        <Styled.ReceptDetails>
          Date of purchase: {transactionDate}
        </Styled.ReceptDetails>
        <Styled.ReceptDetails>
          <strong>Total amount paid: ${amountPaid?.toFixed(2)}</strong>
        </Styled.ReceptDetails>
      </Styled.SummaryWrapper>
    </SectionPage>
  );
};

export default PaymentSummary;

export const PaymentSummaryHeading: JSX.Element = (
  <Heading level={2}>Payment summary</Heading>
);
