import React from 'react';
import { SkeletonContainer, SkeletonText } from '@snsw-gel/react';
import * as Styled from './PurchaseCostDetails.styled';

const textSkeletonWidth = 240;
const amountSkeletonWidth = 64;

const PurchaseCostDetailsSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      <Styled.CostList>
        <Styled.CostItem skeleton>
          <SkeletonText width={textSkeletonWidth} noMargin />
          <SkeletonText width={amountSkeletonWidth} noMargin />
        </Styled.CostItem>
        <Styled.CostItem skeleton>
          <SkeletonText width={textSkeletonWidth} noMargin />
          <SkeletonText width={amountSkeletonWidth} noMargin />
        </Styled.CostItem>
        <Styled.CostItem skeleton>
          <SkeletonText width={textSkeletonWidth} noMargin />
          <SkeletonText width={amountSkeletonWidth} noMargin />
        </Styled.CostItem>
      </Styled.CostList>
    </SkeletonContainer>
  );
};

export default PurchaseCostDetailsSkeleton;
