import React from 'react';
import { useHistory } from 'react-router-dom';
import { SectionPage } from '@snsw-gel/react';
import { drupalPageLocation } from 'config/constants';
import StyledButtonLink from 'utils/styling/StyledButtonLink';
import * as Styled from './RedirectActions.styled';

const RedirectActions: React.FC = () => {
  const history = useHistory();

  return (
    <SectionPage>
      <Styled.ButtonContainer>
        <StyledButtonLink href={drupalPageLocation.vhc}>
          Cancel
        </StyledButtonLink>
        <StyledButtonLink
          variant='secondary'
          onClick={() => history.push('/vhc')}
        >
          Buy another report
        </StyledButtonLink>
      </Styled.ButtonContainer>
    </SectionPage>
  );
};

export default RedirectActions;
