import styled from 'styled-components';
import { Accordion as SNSWAccordian } from '@snsw-gel/react';

// designer request to overwrite default Kiama
export const Accordian = styled(SNSWAccordian)`
  > div:first-child > button {
    width: auto;
    min-width: 0;
  }
`;

export const AccordionWrapper = styled.div`
  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    margin-left: calc(1em + 1.5px);
  }
`;
