import React from 'react';
import { useParams } from 'react-router-dom';
import { SectionHeader } from '@snsw-gel/react';
import { TransactionTypeParams, RoutePathParams } from 'utils/route/urlParams';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import * as Styled from './Header.styled';

const Header: React.FC<HeaderProps> = ({
  headingTitle,
  success = false,
}) => {
  const { transactionType } = useParams<RoutePathParams>();
  const defaultTitle =
    transactionType && isValidTransactionType(transactionType)
      ? headerTitles[transactionType]
      : defaultHeaderTitle;
  const headingText = headingTitle ?? defaultTitle;

  const headerComponent = success ? (
    <Styled.HeadingIconWrapper>
      <Styled.IconNotificationSuccess
        size='lg'
        data-testid='header-success-icon'
      />
      <span>{headingText}</span>
    </Styled.HeadingIconWrapper>
  ) : (
    headingText
  );

  return (
    <SectionHeader
      id='header'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Kiama props
      title={headerComponent}
    />
  );
};

export default Header;

interface HeaderProps {
  back?: boolean;
  headingTitle?: string;
  success?: boolean;
}

export const headerTitles: Record<TransactionTypeParams, string> = {
  frc: 'Check a vehicle registration',
  vhc: 'Buy a vehicle history report',
};
export const defaultHeaderTitle = 'Vehicle registration';
export const genericTransactionErrorHeading = "We're sorry about this...";
