import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { mainElementId } from 'config/constants';
import usePrevious from 'utils/hooks/usePrevious';

const PageChangeMonitor: React.FC = () => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);

  useEffect(() => {
    if (!previousPathname) {
      return;
    }
    /**
     * We don't want to run focus on a page that was a automatic redirect and re-entry points
     * - silent login redirect to /frc and /vhc
     * - confirmation UUID redirect
     */
    const previousPathIsSilentLoginRedirect = previousPathname === '/';
    const previousPathIsConfirmationRedirect = matchPath(previousPathname, {
      path: ['/confirmation/:receiptUUID', '/view-receipt/:receiptUUID'],
      exact: true,
      strict: false,
    });
    if (
      previousPathIsSilentLoginRedirect ||
      previousPathIsConfirmationRedirect
    ) {
      return;
    }

    if (previousPathname && previousPathname !== pathname) {
      focusMainContent();
    }
  }, [pathname]);

  return null;
};

export default PageChangeMonitor;

const focusMainContent = (): void => {
  const mainElement = document.getElementById(mainElementId);

  if (mainElement) {
    mainElement.tabIndex = -1;
    mainElement.focus();
    mainElement.scrollIntoView();
  }
};
