import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const ButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    flex-direction: row;
  }
`;
