import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';
import userReducer from './slice/user';
import vehicleReducer from './slice/vehicle';
import confirmationReducer from './slice/confirmation';

const reducers = combineReducers({
  user: userReducer,
  vehicle: vehicleReducer,
  confirmation: confirmationReducer,
});
export type ReducerRootState = ReturnType<typeof reducers>;

const reduxStore = configureStore({
  reducer: reducers,
});

export type ReducersStoreType = typeof reduxStore;
export type StoreType = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;

export const useStoreDispatch: () => AppDispatch = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<StoreType> = useSelector;
const storeInitialState = reduxStore.getState();

export const setupReduxStore = (
  preloadedState?: Partial<PreloadedState<ReducerRootState>>
): ReducersStoreType => {
  return configureStore({
    reducer: reducers,
    preloadedState: {
      ...storeInitialState,
      ...preloadedState,
    },
  });
};

export default reduxStore;
