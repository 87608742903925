import { DriveMessageDetail } from 'api/types/drives';

const getUnsuccessfulMessages = (
  messages: DriveMessageDetail[] | null
): DriveMessageDetail | null => {
  if (!messages) return null;

  return (
    messages.find((message) => !validSeverities.includes(message.severity)) ||
    null
  );
};

export default getUnsuccessfulMessages;

const validSeverities = ['SUCCESS', 'WARNING'];
