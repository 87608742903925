import React from 'react';
import {
  SkeletonContainer,
  SkeletonHeading,
  SkeletonText,
} from '@snsw-gel/react';
import * as Styled from './VehicleDetailsCard.styled';

const VehicleDetailsCardSkeleton: React.FC<VehicleDetailsCardSkeletonProps> = ({
  border,
  actionComponent,
}) => {
  return (
    <SkeletonContainer>
      <Styled.CardContainer border={border}>
        <Styled.MainContentWrapper>
          <Styled.VehicleDetailsContainer skeleton>
            <Styled.SkeletonPlateNumberWrapper>
              <SkeletonHeading width={200} level={4} noMargin />
            </Styled.SkeletonPlateNumberWrapper>
            <SkeletonText noMargin />
            <SkeletonText noMargin />
            <SkeletonText noMargin />
          </Styled.VehicleDetailsContainer>
          {actionComponent}
        </Styled.MainContentWrapper>
      </Styled.CardContainer>
    </SkeletonContainer>
  );
};

export default VehicleDetailsCardSkeleton;

interface VehicleDetailsCardSkeletonProps {
  border?: boolean;
  actionComponent?: JSX.Element;
}
