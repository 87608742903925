import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SectionPage } from '@snsw-gel/react';
import Header, { headerTitles } from 'components/Header/Header';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { systemFailureError } from 'config/errorMessages';
import { drupalPageLocation } from 'config/constants';
import { stepErrorPage } from 'config/steps';
import StyledButtonLink from 'utils/styling/StyledButtonLink';
import { errorHistoryState } from 'utils/route/urlParams';
import getTransactionTypeFromHistoryState from 'utils/route/getTransactionTypeFromHistoryState';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';
import * as Styled from './ErrorPage.styled';

const ErrorPage: React.FC = () => {
  const location = useLocation();

  const state = location.state as errorHistoryState;
  const transactionType = getTransactionTypeFromHistoryState(state);
  const headingTitle = transactionType
    ? headerTitles[transactionType]
    : genericTransactionErrorHeading;

  useEffect(() => {
    transactionCleanupEvents();
  }, []);

  return (
    <>
      <Header headingTitle={headingTitle} />
      <PageContentWrapper stepName={stepErrorPage}>
        <SectionPage>
          <NotificationMessage message={systemFailureError} />
        </SectionPage>
        {transactionType && (
          <SectionPage>
            <Styled.ButtonContainer>
              <StyledButtonLink
                variant='secondary'
                href={drupalPageLocation[transactionType]}
              >
                Quit
              </StyledButtonLink>
            </Styled.ButtonContainer>
          </SectionPage>
        )}
      </PageContentWrapper>
    </>
  );
};

export default ErrorPage;

const genericTransactionErrorHeading = "We're sorry about this...";
