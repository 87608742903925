import { StoreType, useStoreSelector } from 'store';
import { DriveMessageDetail } from 'api/types/drives';
import {
  ValidationResponse,
  VHCValidationResponseDetails,
} from 'api/types/response';

const findSelectedVHCValidation = (
  store: StoreType
): VHCValidationResponseDetails | null => {
  const { vhcValidations, selectedVehicle, isConditionalVehicle } =
    store.vehicle;

  if (isConditionalVehicle) {
    return null;
  }

  if (selectedVehicle === null || vhcValidations === null) {
    return null;
  }

  const { plateNumber, plateType } = selectedVehicle;
  const validation = vhcValidations.find(
    (vehicle) =>
      vehicle?.validationResponse?.plate?.plateNumber === plateNumber &&
      vehicle?.validationResponse?.plate?.plateType === plateType
  );

  return validation ?? null;
};

export const getSelectedVHCValidationResponse =
  (): ValidationResponse | null => {
    return useStoreSelector((store) => {
      const validation = findSelectedVHCValidation(store);
      return validation?.validationResponse ?? null;
    });
  };

export const getSelectedVHCValidationMessages = ():
  | DriveMessageDetail[]
  | null => {
  return useStoreSelector((store) => {
    const validation = findSelectedVHCValidation(store);
    return validation?.validationSystemMessages ?? null;
  });
};
