import React from 'react';
import { Button, SectionPage } from '@snsw-gel/react';
import VehicleDetailsCard from 'components/VehicleDetailsCard/VehicleDetailsCard';
import * as VehicleDetailsCardStyled from 'components/VehicleDetailsCard/VehicleDetailsCard.styled';
import * as Styled from './MultipleVehiclesOptions.styled';

const MultipleVehiclesOptionsSkeleton: React.FC = () => {
  return (
    <SectionPage>
      <Styled.VehicleListContainer skeleton>
        <SkeletonVehicleCard />
        <SkeletonVehicleCard />
      </Styled.VehicleListContainer>
    </SectionPage>
  );
};

export default MultipleVehiclesOptionsSkeleton;

const SkeletonVehicleCard: React.FC = () => {
  return (
    <VehicleDetailsCard
      border
      vehicleDetails={null}
      actionComponent={
        <VehicleDetailsCardStyled.ActionContainer alignItem='center'>
          <Button disabled>Loading...</Button>
        </VehicleDetailsCardStyled.ActionContainer>
      }
    />
  );
};
