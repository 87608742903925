import styled, { css } from 'styled-components';

export const CostList = styled.ul`
  padding: 0;
  list-style: none;
`;

interface CostItemProps {
  bold?: boolean;
  skeleton?: boolean;
}

export const CostItem = styled.li<CostItemProps>`
  display: flex;
  justify-content: space-between;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}

  ${({ skeleton }) =>
    skeleton &&
    css`
      margin: 1.5rem 0;
    `}
`;
