import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import * as Styled from './TimestampNavigation.styled';
import TimestampNavigationSkeleton from './TimestampNavigationSkeleton';

const TimestampNavigation: React.FC<TimestampNavigationProps> = ({
  skeleton = false,
}) => {
  const [currentDate] = useState<Dayjs>(dayjs(new Date()));

  if (skeleton) return <TimestampNavigationSkeleton />;

  return (
    <Styled.ContentWrapper>
      <div>
        <Styled.ContentText data-testid='dateTimeStamp'>
          <time dateTime={currentDate.format()}>
            {currentDate.format('D MMMM YYYY h:mm A')}
          </time>
        </Styled.ContentText>
      </div>
    </Styled.ContentWrapper>
  );
};
interface TimestampNavigationProps {
  skeleton: boolean;
}

export default TimestampNavigation;
