type ReCaptchaHeaderType = 'silent' | 'challenge';

interface GoogleRecaptchaHeaders {
  'x-recaptcha-token': string;
  'x-recaptcha-type': ReCaptchaHeaderType;
  'x-recaptcha-action'?: string;
}

interface GetHeadersProps {
  type: ReCaptchaHeaderType;
  widgetID?: number;
  action?: string;
}

/**
 * Returns reCAPTCHA headers for Apigee on success or empty object on reject
 * add HTML tag for Google reCAPTCHA scripts and site key to env files
 *
 * action: action event for google reCAPTCHA to match with the verification call
 */
const getHeaders = async (
  data: GetHeadersProps
): Promise<{} | GoogleRecaptchaHeaders> => {
  const { type, widgetID, action } = data;

  // check silent requirements
  const recaptchaSilentSiteKey =
    process.env.REACT_APP_RECAPTCHA_SILENT_SITE_KEY;
  try {
    const recaptchaToken = new Promise((resolve, reject) => {
      grecaptcha.enterprise.ready(async () => {
        try {
          if (type === 'silent' && recaptchaSilentSiteKey && action) {
            const token = await grecaptcha.enterprise.execute(
              recaptchaSilentSiteKey,
              {
                action,
              }
            );
            resolve(token);
          }
          if (type === 'challenge' && widgetID !== undefined) {
            const token = grecaptcha.enterprise.getResponse(widgetID);
            if (!token) {
              reject(token);
              return;
            }
            grecaptcha.enterprise.reset(widgetID);
            resolve(token);
          }
          reject();
        } catch (e) {
          // TODO: remove console.log
          console.log(e);
          reject();
        }
      });
    });

    return {
      'x-recaptcha-token': await recaptchaToken,
      'x-recaptcha-type': type,
      ...(action && { 'x-recaptcha-action': action }),
    };
  } catch {
    return {};
  }
};

const GoogleReCaptcha = {
  getHeaders,
};

export default GoogleReCaptcha;
