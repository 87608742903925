import React from 'react';
import { BrandIconInformation } from '@snsw-gel/react';
import ReviewInfoContent from 'components/ReviewInfoContent/ReviewInfoContent';
import importantInformationMarkdown from './importantInformation.md';
import {
  hyperlinkNewPageExternal,
  formattedUnorderedListMobile,
} from 'utils/providers/markdownRenderer';

const ImportantInformation: React.FC = () => (
  <ReviewInfoContent
    title='Important information'
    Icon={BrandIconInformation}
    markdown={importantInformationMarkdown}
    markdownRenderSettings={{
      ...hyperlinkNewPageExternal,
      ...formattedUnorderedListMobile,
    }}
  />
);

export default ImportantInformation;
