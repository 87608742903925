import React from 'react';
import * as Styled from './PlateNumberInput.styled';

// Copied from common components to upgrade to Gel 3
const PlateNumberInput: React.FC<PlateNumberInputProps> = ({
  value,
  updatePlateNumber,
  errorMessage,
  label = '',
  helpMessage,
  inputWidth,
  ariaLablledBy,
  required = false,
}) => {
  const handlePlateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueToUpper = event.target.value.toUpperCase();
    const cleanPlateNumber = valueToUpper.replace(/[. -]/g, '');
    if (cleanPlateNumber !== value) {
      updatePlateNumber(cleanPlateNumber);
    }
  };

  return (
    <Styled.ModifiedFormGroup
      id='plateNumberInput'
      name='plateNumber'
      label={label}
      helpMessage={helpMessage}
      hasError={Boolean(errorMessage)}
      errorMessage={errorMessage}
      $hideLabel={label === ''}
    >
      <Styled.ModifiedInput
        aria-labelledby={ariaLablledBy}
        value={value}
        onChange={handlePlateChange}
        inputWidth={inputWidth}
        spellCheck='false'
        required={required}
      />
    </Styled.ModifiedFormGroup>
  );
};

export const propsAreEqual = (
  prevProps: PlateNumberInputProps,
  nextProps: PlateNumberInputProps
): boolean =>
  prevProps.value === nextProps.value &&
  prevProps.errorMessage === nextProps.errorMessage;

export default React.memo(PlateNumberInput, propsAreEqual);

export interface PlateNumberInputProps {
  value: string;
  updatePlateNumber: (newPlateNumber: string) => void;
  errorMessage?: string;
  label?: string;
  helpMessage?: string;
  inputWidth?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  ariaLablledBy?: string;
  required?: boolean;
}
