import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SectionPage } from '@snsw-gel/react';
import { UserToken } from '@rmstransactions/components';
import { getConfirmationData } from 'api/api';
import { useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import Header, { headerTitles } from 'components/Header/Header';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { drupalPageLocation } from 'config/constants';
import { systemFailureError } from 'config/errorMessages';
import { stepErrorPage } from 'config/steps';
import { handleResponseData } from 'utils/api/httpClient';
import getUnsuccessfulMessages from 'utils/validation/getUnsuccessfulMessages';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';
import StyledButtonLink from 'utils/styling/StyledButtonLink';
import * as Styled from './ErrorConfirmationBusinessPage.styled';

const ErrorConfirmationBusinessPage: React.FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const message =
    confirmationData?.messages &&
    getUnsuccessfulMessages(confirmationData.messages);

  useEffect(() => {
    const handleConfirmationData = async () => {
      /**
       * The default for getConfirmationData() is "/support/*" which requires a UUID
       * we do not provide the UUID on redirect error from payment gateway
       * we avoid making the call if we are missing user token and show default error message
       */
      if (UserToken.getValue() === null) {
        dispatch(
          confirmationSliceActions.setConfirmationData(defaultErrorResponse)
        );
        transactionCleanupEvents();
        return;
      }

      setIsLoading(true);
      const result = await getConfirmationData();
      const data = handleResponseData(result);
      dispatch(
        confirmationSliceActions.setConfirmationData(
          data ?? defaultErrorResponse
        )
      );

      setIsLoading(false);
      transactionCleanupEvents();
    };

    if (message === undefined) {
      handleConfirmationData();
    }
  }, [message]);

  return (
    <>
      <Header headingTitle={headerTitles.vhc} />
      <PageContentWrapper stepName={stepErrorPage}>
        <SectionPage>
          <NotificationMessage message={message ?? systemFailureError} />
        </SectionPage>
        <Styled.ButtonContainer>
          <StyledButtonLink variant='secondary' href={drupalPageLocation.vhc}>
            Quit
          </StyledButtonLink>
        </Styled.ButtonContainer>
      </PageContentWrapper>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default ErrorConfirmationBusinessPage;

const defaultErrorResponse = {
  plateNumber: null,
  emailAddress: null,
  receiptNumber: null,
  transactionDate: null,
  amountPaid: null,
  vhcPDFBase64: null,
  receiptPDFBase64: null,
  messages: [systemFailureError],
};
