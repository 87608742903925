const removeNullUndefinedFromObject = <T extends Record<string, unknown>>(
  obj: T
): Partial<Record<keyof T, NonNullable<T[keyof T]>>> => {
  const clone = { ...obj };
  Object.entries(clone).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete clone[key];
    }
  });
  return clone as Partial<Record<keyof T, NonNullable<T[keyof T]>>>;
};

export default removeNullUndefinedFromObject;
