import styled, { css } from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const ContentWrapper = styled.div<ContentWrapperProps>`
  ${({ skeleton }) =>
    skeleton &&
    css`
      margin: 1.5rem 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    `}

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 0;

    ${({ skeleton }) =>
      skeleton &&
      css`
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
      `}
  }
`;

interface ContentWrapperProps {
  skeleton?: boolean;
}

export const ContentText = styled.p`
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin: 0;
  }
`;
