import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useParams, useHistory, useLocation } from 'react-router-dom';
import { SuccessIndicator } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import { getConfirmationData } from 'api/api';
import { handleResponseData } from 'utils/api/httpClient';
import vhcVehicleSession from 'utils/session/vhcVehicleData';
import {
  confirmationHistoryState,
  errorPath,
  errorPathConfirmationBusiness,
  RoutePathParams,
  transactionTypes,
} from 'utils/route/urlParams';
import { VHC_TUTD_APP_NAME, transactionErrorKey } from 'config/constants';
import { stepConfirmationPage } from 'config/steps';
import Header from 'components/Header/Header';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import TransactionLinks from 'components/TransactionLinks/TransactionLinks';
import ThumbsUpThumbsDown from 'components/ThumbsUpThumbsDown/ThumbsUpThumbsDown';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import sessionReceiptUUID from 'utils/session/sessionReceiptUUID';
import ConfirmationDescription from './ConfirmationDescription/ConfirmationDescription';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import RedirectToSearch from './RedirectToSearch/RedirectToSearch';
import ReceiptActions from './ReceiptActions/ReceiptActions';
import NotifyLeaving from './NotifyLeaving/NotifyLeaving';

const ConfirmationPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { receiptUUID } = useParams<RoutePathParams>();
  const state = location?.state as confirmationHistoryState;
  const confirmationRedirectReceiptUUID = state?.receiptUUID;
  const providedReceiptUUID =
    receiptUUID ??
    confirmationRedirectReceiptUUID ??
    sessionReceiptUUID.getValue();

  const successIndicatorTimer = useRef(0);
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const [showSuccessIndicator, setShowSuccessIndicator] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleConfirmationData = async (uuid: string) => {
      setIsLoading(true);
      const result = await getConfirmationData(uuid);
      const data = handleResponseData(result);
      if (!data) {
        history.push(errorPath, { [transactionErrorKey]: 'vhc' });
        return;
      }
      dispatch(confirmationSliceActions.setConfirmationData(data));
      setIsLoading(false);
      setShowSuccessIndicator(true);

      window.clearTimeout(successIndicatorTimer.current);
      successIndicatorTimer.current = window.setTimeout(() => {
        setShowSuccessIndicator(false);
      }, successIndicatorDurationInMilis);
    };

    vhcVehicleSession.clearData();
    handleConfirmationData(providedReceiptUUID ?? '');

    return () => {
      window.clearTimeout(successIndicatorTimer.current);
    };
  }, [providedReceiptUUID]);

  const hasServerMessages =
    confirmationData && confirmationData.messages.length > 0;
  if (hasServerMessages) {
    return <Redirect to={errorPathConfirmationBusiness} />;
  }

  const headingTitleStatus = confirmationData ? 'successful' : 'pending';

  return (
    <>
      <SetDocumentTitle />
      <LoadingOverlay visible={isLoading} label={loadingOverlayText} />
      <SuccessIndicator
        active={showSuccessIndicator}
        label={successIndicatorText}
      />
      <Header headingTitle={`Report purchase ${headingTitleStatus}`} success />
      <PageContentWrapper>
        <ConfirmationDescription />
        <HorizontalRule />
        <PaymentSummary />
        <ReceiptActions />
        <RedirectToSearch />
        <ThumbsUpThumbsDown
          applicationName={VHC_TUTD_APP_NAME}
          label='How was your purchase experience?'
        />
        <TransactionLinks />
      </PageContentWrapper>
      <NotifyLeaving />
    </>
  );
};

export default ConfirmationPage;

const loadingOverlayText =
  'Processing, please wait. Do not refresh or navigate away.';
const successIndicatorText = 'Payment successful';
const successIndicatorDurationInMilis = 3000;

// Fix for heading, component renders too slow before GA post
const SetDocumentTitle: React.FC = () => {
  document.title = `Service NSW - ${transactionTypes['vhc']} - ${stepConfirmationPage}`;
  return <></>;
};
