import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Button } from '@snsw-gel/react';
import { FRC_TUTD_APP_NAME } from 'config/constants';
import { stepFRCReviewPage } from 'config/steps';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import ReviewVehicleDetails from 'components/ReviewVehicleDetails/ReviewVehicleDetails';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import TransactionLinks from 'components/TransactionLinks/TransactionLinks';
import ThumbsUpThumbsDown from 'components/ThumbsUpThumbsDown/ThumbsUpThumbsDown';
import { RoutePathParams } from 'utils/route/urlParams';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import VehicleInformation from './VehicleInformation/VehicleInformation';
import BeforeBuyingAccordion from './BeforeBuyingAccordion/BeforeBuyingAccordion';
import ConditionalNotification from './ConditionalNotification/ConditionalNotification';
import RegistrationRestriction from './RegistrationRestriction/RegistrationRestriction';
import RegistrationAndInsuranceInfo from './RegistrationAndInsuranceInfo/RegistrationAndInsuranceInfo';
import BuyAReportPanel from './BuyAReportPanel/BuyAReportPanel';
import * as Styled from './FreeRegoCheck.styled';

const FreeRegoCheck: React.FC = () => {
  const { transactionType } = useParams<RoutePathParams>();
  const vehicleDetails = getSelectedVehicleDetails();
  const history = useHistory();

  if (!vehicleDetails) return <Redirect to={`/${transactionType}`} />;

  return (
    <PageContentWrapper stepName={stepFRCReviewPage}>
      <ConditionalNotification />
      <RegistrationRestriction />
      <ReviewVehicleDetails />
      <HorizontalRule />
      <VehicleInformation />
      <HorizontalRule />
      <RegistrationAndInsuranceInfo />
      <BuyAReportPanel />
      <BeforeBuyingAccordion />
      <Styled.ButtonContainer>
        <Button
          data-testid='frcFindVehicleButton'
          onClick={() => {
            history.push('/frc');
          }}
        >
          Check another registration
        </Button>
      </Styled.ButtonContainer>
      <ThumbsUpThumbsDown
        applicationName={FRC_TUTD_APP_NAME}
        label='How was your vehicle registration check?'
      />
      <TransactionLinks />
    </PageContentWrapper>
  );
};

export default FreeRegoCheck;
