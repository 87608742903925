import React from 'react';
import styled from 'styled-components';
import { Components } from 'react-markdown';
import { TextLink, tokens } from '@snsw-gel/react';
import {
  DisableTelOnTabletDesktop,
  detectIOS,
} from '@rmstransactions/components';

const { isDeviceIOSSafari } = detectIOS;

/*
  react-markdown can take in the prop 'components' to custom render HTML elements,
  <ReactMarkdown components={componentSettings}>
*/

export const hyperlinkNewPageExternal: Components = {
  a: ({ children, href, title, ...rest }) => (
    <TextLink href={href} title={title} {...rest} isExternal>
      {children}
    </TextLink>
  ),
};

export const hyperlinkDownload: Components = {
  a: ({ children, href, title, ...rest }) =>
    isDeviceIOSSafari() ? (
      <TextLink
        href={process.env.PUBLIC_URL + href}
        title={title}
        {...rest}
        target='_blank'
        rel='noopener noreferrer'
      >
        {children}
      </TextLink>
    ) : (
      <TextLink
        href={process.env.PUBLIC_URL + href}
        title={title}
        {...rest}
        download
      >
        {children}
      </TextLink>
    ),
};

const StyledUnorderedList = styled.ul`
  @media screen and (max-width: ${tokens.breakpoints.sm}px) {
    padding-left: 0;
    > li {
      padding: 0;
    }
  }
`;

export const formattedUnorderedListMobile: Components = {
  ul: ({ children }) => {
    return <StyledUnorderedList>{children}</StyledUnorderedList>;
  },
};

export const notificationLinks: Components = {
  a: ({ children, href, title, ...rest }) => {
    if (!href) {
      return <>{children}</>;
    }

    if (href.startsWith(serviceNSWHostname)) {
      // return external link without icon for the default SNSW url
      return (
        <a href={href} target='_blank' rel='noopener noreferrer'>
          {children}
        </a>
      );
    }

    if (href.startsWith('tel:')) {
      // pass on the LABEL value instead of href (legacy component)
      return DisableTelOnTabletDesktop({
        phoneNumber: children[0] as string,
      });
    }

    return (
      <TextLink href={href} title={title} isExternal {...rest}>
        {children}
      </TextLink>
    );
  },
};

export const serviceNSWHostname = 'https://www.service.nsw.gov.au/';
