import React from 'react';
import {
  SectionPage,
  IconNotificationSuccess,
  IconNotificationWarning,
  Heading,
} from '@snsw-gel/react';
import dayjs from 'dayjs';
import { drupalPageLocation } from 'config/constants';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import isTrailerOrCaravan from 'utils/validation/isTrailerOrCaravan';
import * as Styled from './RegistrationAndInsuranceInfo.styled';
import {
  GetRegistrationMessage,
  getExpiryLabel,
  getRegistrationStatusLabel,
  getRegistrationTimeMessage,
  isInsuranceExpired,
  isMessageToBeDisplayed,
  isRegistrationExpired,
  isRenewLinkToBeDisplayed,
} from './RegistrationAndInsuranceInfoUtils';

const RegistrationAndInsuranceInfo: React.FC = () => {
  const vehicleDetails = getSelectedVehicleDetails();

  const getRegistrationDetails = () => {
    const expiryMessage = getRegistrationTimeMessage(vehicleDetails);
    const expiryLabel = getExpiryLabel(vehicleDetails);

    return (
      <>
        <div>
          {expiryLabel} {expiryMessage}
        </div>
        {isRenewLinkToBeDisplayed(vehicleDetails) && (
          <div>
            <p>
              <a
                href={drupalPageLocation.renew_rego}
                target='_blank'
                rel='noopener noreferrer'
              >
                Renew registration now
              </a>
            </p>
          </div>
        )}
        {isMessageToBeDisplayed(vehicleDetails) && (
          <div>
            {vehicleDetails?.registrationStatus && (
              <GetRegistrationMessage
                registrationStatusMessage={vehicleDetails.registrationStatus.toLowerCase()}
                isRegistrationExpired={isRegistrationExpired(vehicleDetails)}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const getRegistrationIcon = () => {
    const ariaLabelId = 'registration-icon-status';
    if (
      vehicleDetails?.registrationStatus &&
      vehicleDetails?.registrationStatus === 'Active' &&
      !isRegistrationExpired(vehicleDetails)
    ) {
      return <SuccessIconWrapper ariaLabelId={ariaLabelId} />;
    } else {
      return <WarningIconWrapper ariaLabelId={ariaLabelId} />;
    }
  };

  const getPolicyIconStatus = () => {
    const ariaLabelId = 'policy-icon-status';
    if (vehicleDetails && !isInsuranceExpired(vehicleDetails)) {
      return <SuccessIconWrapper ariaLabelId={ariaLabelId} />;
    } else {
      return <WarningIconWrapper ariaLabelId={ariaLabelId} />;
    }
  };

  const policyExpiryDate = vehicleDetails?.policyDetails
    ? dayjs(vehicleDetails.policyDetails.endDate).format('D MMMM YYYY')
    : 'Not available';
  const policyInsurerCode =
    vehicleDetails?.policyDetails &&
    vehicleDetails?.policyDetails.insurerCode !== null
      ? vehicleDetails.policyDetails.insurerCode
      : 'Not available';
  const policyInsurerName =
    vehicleDetails?.policyDetails &&
    vehicleDetails?.policyDetails.insurerName !== null
      ? vehicleDetails.policyDetails.insurerName
      : 'Not available';

  return (
    vehicleDetails && (
      <>
        <Heading level={2}>Is this vehicle ready to drive?</Heading>
        <SectionPage>
          <Styled.GridWrapper>
            <React.Fragment>
              <Styled.GridItem>{getRegistrationIcon()}</Styled.GridItem>
              <Styled.GridItem bold>
                {getRegistrationStatusLabel(vehicleDetails)}
              </Styled.GridItem>
              <Styled.GridContent>
                {getRegistrationDetails()}
              </Styled.GridContent>
            </React.Fragment>
            {!isTrailerOrCaravan(vehicleDetails) && (
              <React.Fragment>
                <Styled.GridItem>{getPolicyIconStatus()}</Styled.GridItem>
                <Styled.GridItem bold>
                  <div>CTP Insurance</div>
                </Styled.GridItem>
                <Styled.GridContent>
                  <div>{`Insurance expiry:  ${policyExpiryDate}`}</div>
                  <div>{`Insurer's name:  ${policyInsurerName}`}</div>
                  <div>{`Insurer's code:  ${policyInsurerCode}`}</div>
                </Styled.GridContent>
              </React.Fragment>
            )}
          </Styled.GridWrapper>
        </SectionPage>
      </>
    )
  );
};

export default RegistrationAndInsuranceInfo;

export const SuccessIconWrapper: React.FC<{ ariaLabelId: string }> = ({
  ariaLabelId,
}) => {
  return (
    <Styled.IconWrapper>
      <IconNotificationSuccess
        size={Styled.notificationIconSize}
        role='graphics-symbol'
        aria-hidden='false'
        aria-labelledby={ariaLabelId}
      />
      <Styled.IconContextWrapper id={ariaLabelId}>
        Green success icon
      </Styled.IconContextWrapper>
    </Styled.IconWrapper>
  );
};

export const WarningIconWrapper: React.FC<{ ariaLabelId: string }> = ({
  ariaLabelId,
}) => {
  return (
    <Styled.IconWrapper>
      <IconNotificationWarning
        size={Styled.notificationIconSize}
        role='graphics-symbol'
        aria-hidden='false'
        aria-labelledby={ariaLabelId}
      />
      <Styled.IconContextWrapper id={ariaLabelId}>
        Orange warning icon
      </Styled.IconContextWrapper>
    </Styled.IconWrapper>
  );
};
