import React from 'react';
import { ConditionalRegistration } from 'api/types/vehicle';
import * as Styled from './SpecialCondition.styled';
import * as StyledVehicleInformation from '../VehicleInformation.styled';

const SpecialCondition: React.FC<ConditionalRegistration> = ({
  specialCondition,
}) => {
  if (Array.isArray(specialCondition)) {
    return (
      <Styled.SpecialConditionWrapper>
        {specialCondition.map((condition) => (
          <div key={condition}>{condition}</div>
        ))}
      </Styled.SpecialConditionWrapper>
    );
  }
  return (
    <StyledVehicleInformation.GridItem>None</StyledVehicleInformation.GridItem>
  );
};

export default SpecialCondition;
