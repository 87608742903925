import React from 'react';
import { SectionPage, MoreInfoModal, Heading } from '@snsw-gel/react';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import { formatVehicleMakeDescription } from 'utils/providers/formatVehicleMakeDescription';
import isHeavyVehicle from 'utils/validation/isHeavyVehicle';
import isConditionalVehicle from 'utils/validation/isConditionalVehicle';
import isTrailer from 'utils/validation/isTrailer';
import ConditionCode from './ConditionCode/ConditionCode';
import SpecialCondition from './SpecialCondition/SpecialCondition';
import VehicleTermsTable from './VehicleTermsModal/VehicleTermsTable';
import OdometerDetails from './OdometerDetails/OdometerDetails';
import * as Styled from './VehicleInformation.styled';

const VehicleInformation: React.FC = () => {
  const vehicleDetails = getSelectedVehicleDetails();

  const make =
    vehicleDetails && vehicleDetails.vehicleModel?.manufacturer?.description ? (
      <Styled.GridItem uppercase>
        {formatVehicleMakeDescription(
          vehicleDetails.vehicleModel.manufacturer.description
        )}
      </Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const model =
    vehicleDetails && vehicleDetails.vehicleModel?.model.description ? (
      <Styled.GridItem capitalize>
        {vehicleDetails.vehicleModel.model.description.toLowerCase()}
      </Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const color =
    vehicleDetails && vehicleDetails.vehicleColour?.description ? (
      <Styled.GridItem capitalize>
        {vehicleDetails.vehicleColour?.description.toLowerCase()}
      </Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const variant =
    vehicleDetails && vehicleDetails.variant ? (
      <Styled.GridItem capitalize>
        {vehicleDetails.variant.description ?? vehicleDetails.variant.code}
      </Styled.GridItem>
    ) : (
      <Styled.GridItem>None</Styled.GridItem>
    );

  const shape =
    vehicleDetails && vehicleDetails.vehicleModel?.bodyShape.description ? (
      <Styled.GridItem capitalize>
        {vehicleDetails.vehicleModel.bodyShape.description.toLowerCase()}
      </Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const manufactureYear =
    vehicleDetails && vehicleDetails.vehicleModel?.manufactureYear ? (
      <Styled.GridItem>
        {vehicleDetails.vehicleModel.manufactureYear}
      </Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const tareWeight =
    vehicleDetails && vehicleDetails.tareWeight ? (
      <Styled.GridItem>{vehicleDetails.tareWeight} kg</Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const grossVehicleMass =
    vehicleDetails && vehicleDetails.grossVehicleMass ? (
      <Styled.GridItem>{vehicleDetails.grossVehicleMass} kg</Styled.GridItem>
    ) : (
      notAvailableElement
    );

  const registrationConcession =
    vehicleDetails && vehicleDetails.concessionOnRego === 'Y' ? (
      <Styled.GridItem>
        This vehicle has a registration concession. Additional costs may be
        payable when vehicle is transferred.
      </Styled.GridItem>
    ) : (
      <Styled.GridItem>No concessions on this registration</Styled.GridItem>
    );

  const nominatedConfiguration =
    vehicleDetails && isHeavyVehicle(vehicleDetails) ? (
      <Styled.GridItem>
        {vehicleDetails?.configurationDetails?.code +
          ' ' +
          vehicleDetails?.configurationDetails?.description}
      </Styled.GridItem>
    ) : null;

  const conditionCodes = vehicleDetails?.conditions ? (
    ConditionCode(vehicleDetails.conditions)
  ) : (
    <Styled.GridItem>None</Styled.GridItem>
  );

  const specialCondition = vehicleDetails?.conditions?.conditionalRegistration
    ? SpecialCondition(vehicleDetails.conditions.conditionalRegistration)
    : null;

  const odometerDetails =
    vehicleDetails?.vehicleModel?.bodyShape &&
    !isConditionalVehicle(vehicleDetails) &&
    !isTrailer(vehicleDetails.vehicleModel.bodyShape) &&
    OdometerDetails(vehicleDetails);

  const vehicleAttributes = [
    make,
    model,
    variant,
    color,
    shape,
    manufactureYear,
    tareWeight,
    grossVehicleMass,
    nominatedConfiguration,
    registrationConcession,
    conditionCodes,
    specialCondition,
  ];

  return (
    vehicleDetails && (
      <>
        <Heading level={2}>About the vehicle and registration</Heading>
        <SectionPage>
          <Styled.GridWrapper>
            {labels.map((vehicleAttribute, index) => {
              if (vehicleAttributes[index] !== null)
                return (
                  <React.Fragment key={vehicleAttribute}>
                    <Styled.GridItem bold>{vehicleAttribute}</Styled.GridItem>
                    {vehicleAttributes[index]}
                  </React.Fragment>
                );
            })}
          </Styled.GridWrapper>
          {odometerDetails}
        </SectionPage>

        <Styled.ModalWrapper>
          <MoreInfoModal
            linkText={
              <>
                Vehicle terms explained
                <Styled.IconNotificationInfoMargin />
              </>
            }
            title='Vehicle terms explained'
            helpText={<VehicleTermsTable />}
            questionHyperLink
          />
        </Styled.ModalWrapper>
      </>
    )
  );
};

export default VehicleInformation;

const labels = [
  'Make',
  'Model',
  'Variant',
  'Colour',
  'Shape',
  'Manufacture year',
  'Tare weight',
  'Gross vehicle mass',
  'Nominated configuration',
  'Registration concession',
  'Condition codes',
  'Special condition',
];

const notAvailableElement = <Styled.GridItem>Not available</Styled.GridItem>;
