import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Button, SectionPage } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import { stepSelectMultipleVehiclePage } from 'config/steps';
import Header from 'components/Header/Header';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import { errorPath, RoutePathParams } from 'utils/route/urlParams';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import MultipleVehiclesInfo from './MultipleVehiclesInfo/MultipleVehiclesInfo';
import MultipleVehiclesOptions from './MultipleVehiclesOptions/MultipleVehiclesOptions';

const VehicleListPage: React.FC = () => {
  const history = useHistory();
  const { transactionType } = useParams<RoutePathParams>();
  const vehicleDetails = useStoreSelector(
    (state) => state.vehicle.vehicleDetails
  );

  if (!isValidTransactionType(transactionType))
    return <Redirect to={errorPath} />;

  if (vehicleDetails === null || vehicleDetails.length < 1)
    return <Redirect to={`/${transactionType}`} />;

  return (
    <>
      <Header />
      <PageContentWrapper stepName={stepSelectMultipleVehiclePage}>
        <MultipleVehiclesInfo />
        <MultipleVehiclesOptions />
        <SectionPage>
          <Button variant='secondary' onClick={() => history.goBack()}>
            Back
          </Button>
        </SectionPage>
      </PageContentWrapper>
    </>
  );
};

export default VehicleListPage;
