import React from 'react';
import ReactMarkdown from 'react-markdown';
import { DriveMessageDetail } from 'api/types/drives';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import * as Styled from './NotificationMessage.styled';

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  message,
  marginTop = false,
  marginBottom = false,
}) => {
  if (!message) return <></>;

  const { messageTitle, messageDescription, severity } = message;
  const variant =
    severity in alertVariantsMap ? alertVariantsMap[severity] : 'error';
  return (
    <Styled.InPageAlert
      variant={variant}
      title={messageTitle ?? defaultMessageTitle}
      role='alert'
      $marginTop={marginTop}
      $marginBottom={marginBottom}
    >
      <ReactMarkdown components={notificationLinks}>
        {messageDescription ?? defaultMessageDescription}
      </ReactMarkdown>
    </Styled.InPageAlert>
  );
};

export default NotificationMessage;

interface NotificationMessageProps {
  message: null | DriveMessageDetail;
  marginTop?: boolean;
  marginBottom?: boolean;
}

const alertVariantsMap: Record<string, string> = {
  INFORMATION: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const defaultMessageTitle = 'Cannot complete your request';
const defaultMessageDescription =
  'We are unable to access this vehicle information due to a technical error. Please call us on [13 77 88](tel:137788) or visit a [service centre](https://www.service.nsw.gov.au/service-centre). We apologise for any inconvenience.';
