export const isHostFreeRegoCheckApp = (): boolean => {
  const isFRC =
    window.location?.host?.startsWith('free-rego-check') ||
    window.location?.host?.startsWith('free-registration-check');

  return isFRC;
};

export const redirectToCheckRegistrationFRCApp = (): void => {
  window.location.href = `${process.env.REACT_APP_CHECK_REGISTRATION_URL}/frc`;
};
