import React, { useEffect, useState } from 'react';
import { useStoreSelector } from 'store';

const NotifyLeaving: React.FC = () => {
  const hasReceivedReport = useStoreSelector(
    (state) => state.confirmation.hasReceivedReport
  );
  const [interactionDetected, setInteractionDetected] = useState(false);

  useEffect(() => {
    const handleInteraction = () => {
      setInteractionDetected(true);
      window.removeEventListener('click', handleInteraction);
    };

    window.addEventListener('click', handleInteraction);
    return () => {
      window.removeEventListener('click', handleInteraction);
    };
  }, []);

  useEffect(() => {
    if (!hasReceivedReport && interactionDetected) {
      window.addEventListener('beforeunload', onUnloadShowWarning);
    } else {
      window.removeEventListener('beforeunload', onUnloadShowWarning);
    }

    return () =>
      window.removeEventListener('beforeunload', onUnloadShowWarning);
  }, [hasReceivedReport, interactionDetected]);

  return <></>;
};

export default NotifyLeaving;

export const onUnloadShowWarning = (event: BeforeUnloadEvent): void => {
  event.preventDefault();
  event.stopImmediatePropagation();
  event.returnValue = 'leavePage';
};
