import { analyticsServiceV2 } from '@rmstransactions/components';
import {
  stepConfirmationPage,
  stepFindVehiclePage,
  stepFRCReviewPage,
  stepSelectMultipleVehiclePage,
  stepVHCReviewPage,
} from 'config/steps';

const { defaultAnalyticsNullValue } = analyticsServiceV2;

export interface RoutePathParams {
  transactionType?: TransactionTypeParams;
  receiptUUID?: string;
  errorCode?: string;
}

const transactionName = 'Check registration';
export const transactionTypes = {
  frc: 'Free registration check',
  vhc: 'Vehicle history report',
};

export const validTransactionTypes = Object.keys(transactionTypes);

export type TransactionTypeParams = keyof typeof transactionTypes;

export const errorPath = '/error';
export const errorPathConfirmationBusiness = `${errorPath}/confirmation-business`;

export type errorHistoryState = undefined | Record<string, any>;

export type confirmationHistoryState = undefined | Record<string, any>;

// Google analytics
export const googleAnalyticsDefaultInfo = {
  transaction_name: transactionName,
  transaction_type: `${transactionTypes.frc}/${transactionTypes.vhc}`,
  transaction_step: 'unknown',
  transaction_sub_type: defaultAnalyticsNullValue,
};
export const googleAnalyticsTransactionInfo = {
  [errorPath]: {
    transaction_name: transactionName,
    transaction_type: `${transactionTypes.frc}/${transactionTypes.vhc}`,
    transaction_step: 'Error',
    transaction_sub_type: 'api',
  },
  '/frc': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.frc,
    transaction_step: stepFindVehiclePage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/frc/vehicles': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.frc,
    transaction_step: stepSelectMultipleVehiclePage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/frc/review': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.frc,
    transaction_step: stepFRCReviewPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/vhc': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.vhc,
    transaction_step: stepFindVehiclePage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/vhc/vehicles': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.vhc,
    transaction_step: stepSelectMultipleVehiclePage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/vhc/review': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.vhc,
    transaction_step: stepVHCReviewPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  '/confirmation': {
    transaction_name: transactionName,
    transaction_type: transactionTypes.vhc,
    transaction_step: stepConfirmationPage,
    transaction_sub_type: defaultAnalyticsNullValue,
  },
  [errorPathConfirmationBusiness]: {
    transaction_name: transactionName,
    transaction_type: transactionTypes.vhc,
    transaction_step: 'confirmation',
    transaction_sub_type: 'business error',
  },
};
export const googleAnalyticsViewReceiptInfo = {
  transaction_name: transactionName,
  transaction_type: transactionTypes.vhc,
  transaction_step: stepConfirmationPage,
  transaction_sub_type: 'view receipt',
};
export const googleAnalyticsUUIDConfirmationRedirectInfo = {
  transaction_name: transactionName,
  transaction_type: transactionTypes.vhc,
  transaction_step: 'payment gateway redirect',
  transaction_sub_type: defaultAnalyticsNullValue,
};
