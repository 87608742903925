import styled from 'styled-components';
import spacing from 'utils/styling/spacing';

export const SummaryWrapper = styled.div`
  margin: ${spacing.xxxl} 0;
`;

export const ReceptDetails = styled.p`
  margin: 0.5rem 0;
`;

export const SkeletonTextWrapper = styled.div`
  margin: 0 0 1rem 0;
`;
