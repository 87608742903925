import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  Gel3OverrideGothamFont,
  MyAccountNav,
  MyAccountNavConfig,
  RMSGlobalStyle,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';
import { theme, GlobalStyle, gel3Themes } from '@snsw-gel/react';
import { mainElementId } from 'config/constants';
import { Config } from 'config/env';
import store from 'store';
import ErrorBoundaryHandler from 'components/ErrorBoundaryHandler/ErrorBoundaryHandler';
import GoogleAnalyticsMonitor from 'components/GoogleAnalyticsMonitor/GoogleAnalyticsMonitor';
import PageChangeMonitor from 'components/PageChangeMonitor/PageChangeMonitor';
import ConfirmationPage from 'pages/ConfirmationPage/ConfirmationPage';
import ConfirmationRedirect from 'pages/ConfirmationRedirect/ConfirmationRedirect';
import ErrorConfirmationBusinessPage from 'pages/ErrorConfirmationBusinessPage/ErrorConfirmationBusinessPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import FindVehiclePage from 'pages/FindVehiclePage/FindVehiclePage';
import ReviewPage from 'pages/ReviewPage/ReviewPage';
import VehicleListPage from 'pages/VehicleListPage/VehicleListPage';
import SilentLoginRedirectPage from 'pages/SilentLoginRedirectPage/SilentLoginRedirectPage';
import {
  errorPath,
  errorPathConfirmationBusiness,
} from 'utils/route/urlParams';

const { globalNavEnv } = Config;
MyAccountNavConfig.setupGlobalNavUri(globalNavEnv);
MyAccountNavConfig.setLoggedInUser();

const App: React.FC = () => {
  return (
    <>
      <RMSGlobalStyle />
      <Gel3OverrideGothamFont />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <main id={mainElementId}>
          <ThemeProvider theme={theme}>
            <GlobalStyle themes={gel3Themes} />
            <Provider store={store}>
              <BrowserRouter>
                <PageChangeMonitor />
                <ErrorBoundaryHandler>
                  <GoogleAnalyticsMonitor />
                  <Switch>
                    <Route
                      path={errorPathConfirmationBusiness}
                      component={ErrorConfirmationBusinessPage}
                    />
                    <Route path={errorPath} component={ErrorPage} />
                    <Route
                      path='/view-receipt/:receiptUUID'
                      component={ConfirmationPage}
                    />
                    <Route
                      path='/confirmation/:receiptUUID'
                      component={ConfirmationRedirect}
                    />
                    <Route path='/confirmation' component={ConfirmationPage} />

                    <Route
                      path='/:transactionType/review'
                      component={ReviewPage}
                    />
                    <Route
                      path='/:transactionType/vehicles'
                      component={VehicleListPage}
                    />
                    <Route
                      path='/:transactionType/'
                      component={FindVehiclePage}
                    />
                    <Route path='/' component={SilentLoginRedirectPage} />
                  </Switch>
                </ErrorBoundaryHandler>
              </BrowserRouter>
            </Provider>
          </ThemeProvider>
        </main>
      </GlobalNav>
    </>
  );
};

export default App;
