import React from 'react';
import { InPageAlert } from '@snsw-gel/react';
import { useStoreSelector } from 'store';

const NoEmailWarning: React.FC = () => {
  const confirmationData = useStoreSelector((state) => state.confirmation.data);
  const emailAddress = confirmationData?.emailAddress;
  if (!confirmationData || emailAddress !== null) return <></>;

  return (
    <>
      <InPageAlert
        variant='warning'
        title='Download your report before you leave this session'
        role='alert'
      >
        <p>
          Your vehicle history report has not been mailed to you. Download your
          report or send it to yourself via email. It will not be available
          online once you close this window.
        </p>
      </InPageAlert>
    </>
  );
};

export default NoEmailWarning;
