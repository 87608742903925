import React from 'react';
import { SectionPage } from '@snsw-gel/react';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import VehicleDetailsCard from 'components/VehicleDetailsCard/VehicleDetailsCard';
import TimestampNavigation from './TimestampNavigation/TimestampNavigation';
import TransactionSwitch from './TransactionSwitch/TransactionSwitch';
import * as Styled from './ReviewVehicleDetails.styled';

const ReviewVehicleDetails: React.FC = () => {
  const vehicleDetails = getSelectedVehicleDetails();
  const isLoading = vehicleDetails === null;

  return (
    <SectionPage>
      <Styled.VehicleDetailsLayout>
        <Styled.VehicleDetailsContainer>
          <VehicleDetailsCard vehicleDetails={vehicleDetails} />
        </Styled.VehicleDetailsContainer>
        <Styled.TransactionSwitchContainer>
          <TransactionSwitch skeleton={isLoading} />
        </Styled.TransactionSwitchContainer>
        <Styled.TimestampContainer>
          <TimestampNavigation skeleton={isLoading} />
        </Styled.TimestampContainer>
      </Styled.VehicleDetailsLayout>
    </SectionPage>
  );
};

export default ReviewVehicleDetails;
