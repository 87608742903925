import styled from 'styled-components';
import {
  IconNotificationSuccess as SNSWIconNotificationSuccess,
  tokens,
} from '@snsw-gel/react';

export const HeadingIconWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

export const IconNotificationSuccess = styled(SNSWIconNotificationSuccess)`
  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin-top: 0.75rem;
  }
`;
