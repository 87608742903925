import React from 'react';
import { SectionPage } from '@snsw-gel/react';
import ReactMarkdown, { Components } from 'react-markdown';
import * as Styled from './ReviewInfoContent.styled';

const ReviewInfoContent: React.FC<ReviewInfoContentProps> = ({
  title,
  Icon,
  markdown,
  markdownRenderSettings = {},
}) => {
  return (
    <SectionPage>
      <Styled.ContentWrapper>
        <Icon />
        <Styled.MarkdownWrapper>
          {title && (
            <Styled.StyledHeading level={2}>{title}</Styled.StyledHeading>
          )}
          <ReactMarkdown components={markdownRenderSettings}>
            {markdown}
          </ReactMarkdown>
        </Styled.MarkdownWrapper>
      </Styled.ContentWrapper>
    </SectionPage>
  );
};

export default ReviewInfoContent;

interface ReviewInfoContentProps {
  Icon: React.FC<unknown>;
  markdown: string;
  title?: string;
  markdownRenderSettings?: Components;
}
