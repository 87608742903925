import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '@snsw-gel/react';
import { pdfActions } from '@rmstransactions/components';
import { useStoreSelector } from 'store';
import { confirmationSliceActions } from 'store/slice/confirmation';
import { vhcReportFileName } from 'config/constants';
import { emailReceiptActionID } from 'pages/ConfirmationPage/ReceiptActions/ReceiptActions';

const DownloadReportModal: React.FC<DownloadReportModalProps> = ({
  onCloseModal,
}) => {
  const dispatch = useDispatch();
  const confirmationData = useStoreSelector((state) => state.confirmation.data);

  const emailAddress = confirmationData?.emailAddress;
  const vhcPDFBase64 = confirmationData?.vhcPDFBase64;
  if (!vhcPDFBase64 || emailAddress !== null) return <></>;

  const handleDownloadReport = () => {
    pdfActions.downloadBase64PDF(vhcPDFBase64, vhcReportFileName);
    dispatch(confirmationSliceActions.setHasReceivedReport(true));
  };

  const handleEmailReport = () => {
    document.getElementById(emailReceiptActionID)?.scrollIntoView();
    onCloseModal();
  };

  return (
    <Modal
      title={downloadReportModalTitle}
      onClose={onCloseModal}
      buttons={[
        { text: downloadModalButtonLabel, onClick: handleDownloadReport },
        {
          text: emailReportModalButtonLabel,
          onClick: handleEmailReport,
        },
      ]}
    >
      <p>
        It looks like you have not got a copy of your report. Download your
        report or send it to yourself via email.
      </p>
    </Modal>
  );
};

export default DownloadReportModal;

interface DownloadReportModalProps {
  onCloseModal: () => void;
}
export const downloadReportModalTitle = 'Get your report before you leave';
export const downloadModalButtonLabel = 'Download report';
export const emailReportModalButtonLabel = 'Email report';
