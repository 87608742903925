import { TransactionTypeParams } from './urlParams';

const redirectedTransaction = {
  setRedirectedTransaction(transactionType: TransactionTypeParams): void {
    window.sessionStorage.setItem('redirectedTransaction', transactionType);
  },
  getRedirectedTransaction(): TransactionTypeParams | null {
    return window.sessionStorage.getItem(
      'redirectedTransaction'
    ) as TransactionTypeParams | null;
  },
  resetRedirectedTransaction(): void {
    window.sessionStorage.removeItem('redirectedTransaction');
  },
};

export default redirectedTransaction;
