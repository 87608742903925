import React, { useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Header from 'components/Header/Header';
import { errorPath, RoutePathParams } from 'utils/route/urlParams';
import FreeRegoCheck from './FreeRegoCheck/FreeRegoCheck';
import VehicleHistoryCheck from './VehicleHistoryCheck/VehicleHistoryCheck';

const ReviewPage: React.FC = () => {
  const { transactionType } = useParams<RoutePathParams>();
  const content = useMemo(
    () => contentSwitch(transactionType),
    [transactionType]
  );

  return (
    <>
      <Header />
      {content}
    </>
  );
};

export default ReviewPage;

const contentSwitch = (
  transactionRoute: RoutePathParams['transactionType']
) => {
  switch (transactionRoute) {
    case 'frc':
      return <FreeRegoCheck />;
    case 'vhc':
      return <VehicleHistoryCheck />;
    default:
      return <Redirect to={errorPath} />;
  }
};
