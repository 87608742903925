import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Modal } from '@snsw-gel/react';
import termsAndConditionsMarkdown from './termsAndConditions.md';
import './TermsAndConditionsModal.css';

const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  isOpen,
  acceptFunction,
  closeFunction,
}) => {
  return (
    <>
      {isOpen && (
        <Modal
          title='Registration check and vehicle history report'
          buttons={[
            { text: 'Accept', onClick: acceptFunction },
            { text: 'Close', onClick: closeFunction },
          ]}
          onClose={closeFunction}
          className='TermsAndConditionsModal_container'
        >
          <ReactMarkdown>{termsAndConditionsMarkdown}</ReactMarkdown>
        </Modal>
      )}
    </>
  );
};

interface TermsAndConditionsModalProps {
  isOpen: boolean;
  acceptFunction: () => void;
  closeFunction: () => void;
}

export default TermsAndConditionsModal;
