import React from 'react';
import ReactMarkdown from 'react-markdown';
import { InPageAlert, SectionPage } from '@snsw-gel/react';
import { hyperlinkNewPageExternal } from 'utils/providers/markdownRenderer';
import writtenOffInfoMarkdown from './writtenOffInfo.md';
import * as Styled from './WrittenOffInfo.styled';

const WrittenOffInfo: React.FC = () => (
  <SectionPage>
    <InPageAlert variant='info' title='Has this vehicle been written off?'>
      <Styled.contentWrapper>
        <ReactMarkdown components={hyperlinkNewPageExternal}>
          {writtenOffInfoMarkdown}
        </ReactMarkdown>
      </Styled.contentWrapper>
    </InPageAlert>
  </SectionPage>
);

export default WrittenOffInfo;
