import React from 'react';
import { InPageAlert } from '@snsw-gel/react';

const NoEmailWarning: React.FC<NoEmailWarningProps> = ({ visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <InPageAlert
      variant='warning'
      title='Remember to download your report after payment'
    >
      <p>
        You have selected <strong>not</strong> to receive your report via email.
        This means it will not be available online once you close this window.
      </p>
    </InPageAlert>
  );
};

export default NoEmailWarning;

interface NoEmailWarningProps {
  visible: boolean;
}
