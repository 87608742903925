const sessionStorageKey = 'vhcReceiptUUID';

const sessionReceiptUUID = {
  getValue: (): string | null => {
    return sessionStorage.getItem(sessionStorageKey);
  },
  setValue: (uuid: string): void => {
    sessionStorage.setItem(sessionStorageKey, uuid);
  },
  clear: (): void => {
    sessionStorage.removeItem(sessionStorageKey);
  },
};

export default sessionReceiptUUID;
