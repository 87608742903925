import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Button, IconNotificationInfo } from '@snsw-gel/react';
import { helpers } from '@rmstransactions/components';
import { VehicleDetails } from 'api/types/vehicle';
import OdometerDetailsLearnMore from './OdometerDetailsLearnMore/OdometerDetailsLearnMore';
import * as Styled from '../VehicleInformation.styled';

export const odometerLabel = 'Odometer readings*';
export const noOdometerText = 'No recorded readings';
export const disclaimer =
  '*Transport for NSW makes no warranty or guarantee as to accuracy of odometer readings.';

const OdometerDetails: React.FC<VehicleDetails> = ({ odometerDetails }) => {
  const [shownMoreOdometerReadings, setShownMoreOdometerReadings] =
    useState<boolean>(false);
  const hasOdometerDetails = odometerDetails && odometerDetails.length > 0;
  if (!hasOdometerDetails)
    return (
      <div>
        <Styled.GridWrapper>
          <Styled.GridItem bold>{odometerLabel}</Styled.GridItem>
          {notAvailableOdometerDetails}
        </Styled.GridWrapper>
        <p>
          {disclaimer} {<OdometerDetailsLearnMore />}
        </p>
      </div>
    );

  let foundTamperedReading = false;
  const hasMoreThanOneOdometerDetail =
    odometerDetails && hasOdometerDetails && odometerDetails?.length > 1;

  const odometerDisplayDetails = (
    <Styled.GridItem>
      <Styled.OdometerGridWrapper>
        {odometerDetails?.map((odometerDetail, index) => {
          if (index === 0 || (index > 0 && shownMoreOdometerReadings)) {
            const isTampered =
              index < odometerDetails?.length - 1 &&
              parseInt(odometerDetails[index].odometer) <
                parseInt(odometerDetails[index + 1].odometer);
            foundTamperedReading = foundTamperedReading || isTampered;
            return (
              <React.Fragment key={odometerDetail.odometer}>
                <Styled.GridItem bold={isTampered}>
                  {helpers.numeric(odometerDetail.odometer)} km
                </Styled.GridItem>
                <Styled.GridItem bold={isTampered}>
                  {readingDate(odometerDetail.readingDate)}
                </Styled.GridItem>
              </React.Fragment>
            );
          }
        })}
      </Styled.OdometerGridWrapper>
      {hasMoreThanOneOdometerDetail && (
        <Button
          variant='link'
          onClick={() => setShownMoreOdometerReadings((prev) => !prev)}
          data-testid='showLink'
        >
          {shownMoreOdometerReadings ? 'Show less' : 'Show more'}
        </Button>
      )}
    </Styled.GridItem>
  );

  return (
    <div>
      <Styled.GridWrapper>
        <Styled.GridItem bold>{odometerLabel}</Styled.GridItem>
        {odometerDisplayDetails}
      </Styled.GridWrapper>
      {foundTamperedReading ? (
        odometerTamperedNotification
      ) : (
        <p>
          {disclaimer} {<OdometerDetailsLearnMore />}
        </p>
      )}
    </div>
  );
};

export default OdometerDetails;

const notAvailableOdometerDetails = (
  <Styled.GridItem>{noOdometerText}</Styled.GridItem>
);

const odometerTamperedNotification = (
  <>
    <Styled.InfoInPageAlertCopyContainer>
      <Styled.InfoInPageAlertCopyLayout>
        <div>
          <IconNotificationInfo size='md' />
        </div>
        <div>
          <span>
            Inconsistent odometer reading. <OdometerDetailsLearnMore />
          </span>
        </div>
      </Styled.InfoInPageAlertCopyLayout>
    </Styled.InfoInPageAlertCopyContainer>

    <p>{disclaimer}</p>
  </>
);

const readingDate = (inputDate: string) =>
  dayjs(inputDate, 'YYYY-MM-DD').format('D MMMM YYYY');
