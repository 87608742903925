import React from 'react';
import { useParams } from 'react-router-dom';
import { Heading, SectionPage } from '@snsw-gel/react';
import { DisableTelOnTabletDesktop } from '@rmstransactions/components';
import { RoutePathParams } from 'utils/route/urlParams';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';

const MultipleVehiclesInfo: React.FC = () => {
  const { transactionType } = useParams<RoutePathParams>();

  if (!isValidTransactionType(transactionType)) return <></>;

  let transactionLabel;
  if (transactionType === 'frc') {
    transactionLabel = 'free registration check';
  } else if (transactionType === 'vhc') {
    transactionLabel = 'vehicle history report';
  }

  return (
    <SectionPage data-testid='multiple-vehicles-notification'>
      <Heading level={2}>Select Vehicle</Heading>
      <p>
        It looks like there are multiple vehicles for the number plate you have
        entered. Please review and select one for the {transactionLabel}.
      </p>
      <p>
        <strong>{"Can't see your vehicle?"}</strong> Call us on{' '}
        <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or visit a{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.service.nsw.gov.au/service-centre'
        >
          service centre
        </a>
        .
      </p>
    </SectionPage>
  );
};

export default MultipleVehiclesInfo;
