export const vehicleConditionsModifiedCode = {
  V1: 'V01',
  V2: 'V02',
  V5: 'V05',
  V7: 'V07',
  V8: 'V08',
  V9: 'V09',
};

export const vehicleConditions = {
  V01: 'RMS overdimension permit required',
  V02: 'May travel from park to park if in close proximity',
  V05: 'Only to be used in daylight',
  V07: 'Laden weight must not exceed TARE of hauling motorcycle',
  V08: 'Hauling units carrying capacity must be atleast 1520kg',
  V09: 'May travel between worksites not more than 5km distance',
  V10: 'May travel on unsealed roads between worksites not more than 5km distance',
  V14: 'No brakes needed if hauling unit is a tractor or has at least specified capacity',
  V16: 'Loadweight must be approved by RMS or local government body',
  V24: 'No brakes needed if used in specified district',
  V27: 'Not to be driven on freeways',
  V28: 'Complete new heavy vehicle form if operating over 42.5T',
  V30: 'Brakes not necessary if hauled by agricultural tractor in specified district',
  V31: 'Exempt brakes in stated district if hauled by tractor or lorry of at least specified capacity',
  V33: 'Exempt brakes if TARE of hauling unit at least', //?
  V38: 'Only to be used in daylight except in an emergency',
  V39: 'Towing motocycle and side-car must have 3 wheel brakes',
  V40: 'Exempt brakes in area if hauling unit has TARE shown',
  V41: "Crane block sheave not to obstruct driver's vision",
  V42: null,
  V43: 'Taxi inspection report required means RMS HVIS taxi inspection',
  V50: 'Vehicle details (eg seating capacity) to be updated before next renewal',
  V51: 'Only to be driven in left-hand-drive while collecting waste',
  V52: 'No passengers to be carried in rear seat compartment',
  V5T: 'Truck with 5 or more axles',
  V61: null,
  V63: 'RMS overdimension permit required',
  V71: 'Pillion passengers must not be carried',
  V73: 'Vehicle to be floated to and from worksite',
  V75: 'Exempt brakes specified area provided laden weight does not exceed three times TARE of hauling tractor and vehicle is driven at a speed not exceeding 25km/h',
  V78: 'Exempt brakes if used exclusively on flat terrain and towed by a tractor of atleast specified KG TARE at a SPEED not exceeding 25KM/HR',
  V82: 'RMS permit required',
  V87: null,
  V88: null,
  V90: 'Only to be used in daylight except in area below',
  V91: 'RMS inspection required',
  V93: 'Exempt brakes if in area below if towed by tractor on flat terrain',
  V99: 'Personal import-compliance conceded',
  VFL: 'Present inspection report within 60 days of renewal',
  VHE: 'HVIS exempt - HVAIS inspection required',
  VLA: 'LA',
  VP4: 'TARE weight to be established',
  VP5: null,
  VP6: 'Daylight/floodlit worksite use ONLY',
  VP7: 'Dual control vehicle sign required',
  VP8: 'Steering failure warning device/sign required',
  VP9: 'Left-hand drive sign required',
  VQ1: 'Operation restricted to worksite not accessible to public',
  VQ2: 'Flashing amber beacon required and in use when vehicle moving',
  VQ3: 'Only to be driven in left-hand drive when performing work',
  VQ4: 'Left-hand drive vehicle',
  VQ5: 'Dual control vehicle',
  VQ6: 'HVIS inspection to be completed within 1 month',
  VQ7: 'HVIS inspection to be completed within 3 months',
  VR2: 'May only drive the vehicle to and from and compete in CAMs permitted event',
  VR4: 'May only drive the vehicle to and from essential maintenance/repairs and service',
  VR8: 'Lower/upper seat capacity - number of adult passengers (including driver) to be verified/recorded',
  VR9: 'May only travel between sites when unladen',
  VS2: 'Contact (02) 9712 0578 or 0579 re vehicle inspection',
  VWV: 'Vehicle is recorded on the register of written-off vehicles',
};

export const registrationConditions = {
  R20: 'May only be used on a vehicle being registered as a taxi or for disposal after being de-registered as a taxi',
  R46: null,
  R47: 'Specified engine fitted',
  R74: 'NHVAS/HTMMAS - no inspection required / no inspection fee',
  R76: 'NHVAS - mass management',
  R77: 'No CTP required for trader trailer plate',
  R79: 'LWMMAS - livestock',
  R80: 'NHVAS - fatigue management',
  RHR: 'RMS/HVIS inspection required within the next 28 days',
  RHV: null,
  RLF: 'Registration leased',
  RLT: 'Registration leased',
  RLV: 'Safety inspection required every six months',
  RPU: 'Personal use only - Safety inspection required',
  RRS: 'Ride-Share',
};

/**
 * APPENDEX F
 * Any of these codes will trigger a notification of <RegistrationRestriction />
 */
export const registrationRestrictionCodes = {
  R19: 'NSW vehicle inspection required, registration restriction exists',
  R81: 'Imported vehicle, registration restriction exists',
  R84: 'Transfer not allowed, registration restriction exists',
  R89: 'Registration restriction exists',
  V23: 'No compliance plate, registration restriction exists',
  V53: 'Registration restriction exists',
  V56: 'Registration restriction exists',
  V68: 'This registration is not transferable',
  V72: 'Registration restriction exists',
  VP2: 'Registration restriction exists',
  VP3: 'Registration restriction exists',
  VR2: 'Registration restriction exists',
  VR3: 'Registration restriction exists',
  VR5: 'Registration restriction exists',
  VS1: 'Registration restriction exists',
  VWO: 'Vehicle has been written off',
};

// excludes APPENDEX 12 (already formatted)
export const conditionCodesWithDescriptions = {
  ...vehicleConditions,
  ...registrationConditions,
  ...registrationRestrictionCodes,
};

export type ConditionCodes = keyof typeof conditionCodesWithDescriptions;

// to avoid ESLint rule: detect-non-literal-regexp
export const codeDescriptionsToReplaceWithRegex = {
  R46: {
    text: 'Inspection required in',
    regex: /INSPECTION REQUIRED IN/i,
  },
  RHV: {
    text: 'RMS/HVIS inspection will be required by',
    regex: /RMS\/HVIS INSPECTION WILL BE REQUIRED BY/i,
  },
  V33: {
    text: 'Exempt brakes if TARE of hauling unit at least',
    regex: /EXEMPT BRAKES IF TARE OF HAULING UNIT AT LEAST/i,
  },
  V42: {
    text: 'Operation restricted to',
    regex: /OPERATION RESTRICTED TO/i,
  },
  V61: {
    text: 'To be towed only by',
    regex: /TO BE TOWED ONLY BY/i,
  },
  V87: {
    text: 'Modified as per report number',
    regex: /MODIFIED AS PER REPORT NUMBER/i,
  },
  V88: {
    text: 'Modified for wheelchair transport as per',
    regex: /MODIFIED FOR WHEELCHAIR TRANSPORT AS PER/i,
  },
  V90: {
    text: 'Only to be used in daylight except in area below',
    regex: /ONLY TO BE USED IN DAYLIGHT EXCEPT IN AREA BELOW/i,
  },
  VP5: {
    text: 'Full chassis number is',
    regex: /FULL CHASSIS NUMBER IS/i,
  },
};
