import { validation } from '@rmstransactions/components';

export const validateEmail = (email: string): EmailValidationErrors[] => {
  const validationErrors = [] as EmailValidationErrors[];

  if (email === '') {
    validationErrors.push('EMPTY_FIELD');
  }
  if (email && !validation.email(email)) {
    validationErrors.push('INVALID_EMAIL');
  }

  return validationErrors;
};

export default validateEmail;

export type EmailValidationErrors = 'EMPTY_FIELD' | 'INVALID_EMAIL';
export const emailValidationErrorsData: {
  [key in EmailValidationErrors]: string;
} = {
  EMPTY_FIELD: 'Please enter an email address.',
  INVALID_EMAIL: 'Please enter a valid email address',
};
