import { VehicleDetails } from 'api/types/vehicle';
import isTrailer from './isTrailer';

const isTrailerOrCaravan = (vehicle: VehicleDetails): boolean => {
  return (
    vehicle &&
    vehicle.vehicleModel?.bodyShape !== null &&
    vehicle.vehicleModel?.bodyShape !== undefined &&
    (isTrailer(vehicle.vehicleModel?.bodyShape) ||
      vehicle.vehicleModel?.bodyShape.description
        .toLowerCase()
        .includes('caravan'))
  );
};

export default isTrailerOrCaravan;
