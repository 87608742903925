import styled, { css } from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const VehicleListContainer = styled.div<VehicleListContainerProps>`
  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    margin: 3.5rem 0;
  }

  ${({ skeleton }) =>
    skeleton &&
    css`
      > div {
        margin: 2rem 0;
      }
    `}
`;

interface VehicleListContainerProps {
  skeleton?: boolean;
}
