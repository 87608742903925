import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import InactiveTimeout from 'components/InactiveTimeout/InactiveTimeout';
import { drupalPageLocation } from 'config/constants';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import {
  errorHistoryState,
  RoutePathParams,
  TransactionTypeParams,
} from 'utils/route/urlParams';
import getTransactionTypeFromHistoryState from 'utils/route/getTransactionTypeFromHistoryState';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';

const TimeoutHandler: React.FC = () => {
  const transactionPath = checkTransactionPath();
  return <InactiveTimeout modalButtons={modalButtons[transactionPath]} />;
};

export default TimeoutHandler;

export const checkTransactionPath = (): TransactionTypeParams => {
  const location = useLocation();
  const { transactionType } = useParams<RoutePathParams>();

  if (location) {
    if (
      location.pathname?.startsWith('/confirmation') ||
      location.pathname?.startsWith('/view-receipt')
    ) {
      return 'vhc';
    }

    const state = location.state;
    if (state) {
      const historyStateTransactionType = getTransactionTypeFromHistoryState(
        state as errorHistoryState
      );
      if (historyStateTransactionType) {
        return historyStateTransactionType;
      }
    }
  }

  if (transactionType && isValidTransactionType(transactionType)) {
    return transactionType;
  }

  return 'frc';
};

export const modalButtons = {
  frc: [
    {
      text: 'Start again',
      onClick: (): void => {
        transactionCleanupEvents();
        window.location.pathname = '/frc';
      },
    },
    {
      text: 'Quit',
      onClick: (): void => {
        transactionCleanupEvents();
        window.location.href = drupalPageLocation.frc;
      },
    },
  ],
  vhc: [
    {
      text: 'Start again',
      onClick: (): void => {
        transactionCleanupEvents();
        window.location.pathname = '/vhc';
      },
    },
    {
      text: 'Quit',
      onClick: (): void => {
        transactionCleanupEvents();
        window.location.href = drupalPageLocation.vhc;
      },
    },
  ],
};
