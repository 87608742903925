import styled from 'styled-components';
import { InPageAlert } from '@snsw-gel/react';

export const OverflowTextInPageAlert = styled(InPageAlert)`
  > div {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
`;
