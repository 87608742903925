import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormCheckbox, Button, SectionPage } from '@snsw-gel/react';
import { KioskService } from '@rmstransactions/components';
import { postRequestPayment } from 'api/api';
import { transactionErrorKey } from 'config/constants';
import { useStoreDispatch } from 'store';
import { vehicleSliceActions } from 'store/slice/vehicle';
import removeNullUndefinedFromObject from 'utils/validation/removeNullUndefinedFromObject';
import { handleResponseData } from 'utils/api/httpClient';
import { getSelectedVHCValidationResponse } from 'utils/providers/getSelectedVHCValidation';
import { errorPath } from 'utils/route/urlParams';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import EmailInput from 'components/EmailInput/EmailInput';
import EmailConfirmationModal from 'components/EmailConfirmationModal/EmailConfirmationModal';
import validateEmail, {
  EmailValidationErrors,
  emailValidationErrorsData,
} from 'components/EmailInput/validateEmail';
import NoEmailWarning from './NoEmailWarning/NoEmailWarning';
import EmptyFormError from './EmptyFormError/EmptyFormError';
import * as Styled from './VHCSubmitForm.styled';

const VHCSubmitForm: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [noSendEmail, setNoSendEmail] = useState<boolean>(false);
  const [formError, setFormError] = useState<FormValidationErrors[]>([]);
  const [confirmEmailModalOpen, setConfirmEmailModalOpen] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    KioskService.sendMessage();
  }, []);

  const vhcVehicleData = getSelectedVHCValidationResponse();
  if (vhcVehicleData === null) return <></>;

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event.target.value);
    if (noSendEmail) {
      setNoSendEmail(false);
    }
    resetFormError();
  };

  const handleOnChangeCheckbox = () => {
    setNoSendEmail((prev) => !prev);
    if (emailAddress !== '') {
      setEmailAddress('');
    }
    resetFormError();
  };

  const resetFormError = () => {
    if (formError.length > 0) {
      setFormError([]);
    }
  };

  const navigateToSearch = () => {
    history.push('/vhc');
  };

  const handleSubmitForm = async () => {
    const formError = validateEmailForm(emailAddress, noSendEmail);
    if (formError.length > 0) {
      setFormError(formError);
      return;
    }

    if (emailAddress) {
      setConfirmEmailModalOpen(true);
      return;
    }

    handlePostRequestPayment();
  };

  const handlePostRequestPayment = async () => {
    setIsLoading(true);
    try {
      dispatch(vehicleSliceActions.saveVehicleStoreToSessionStorage());
    } catch {
      history.push(errorPath, { [transactionErrorKey]: 'vhc' });
      return;
    }

    const plateNumber = vhcVehicleData.plate?.plateNumber ?? '';
    const plateType = vhcVehicleData.plate?.plateType ?? '';
    const amount = vhcVehicleData.vhcPrice
      ? Number.parseFloat(vhcVehicleData.vhcPrice.grossAmount)
      : 0;
    const options = removeNullUndefinedFromObject({
      emailAddress: emailAddress || null,
      kioskId: KioskService.isKiosk() ? KioskService.getKioskId() : null,
    });

    const response = await postRequestPayment({
      plateNumber,
      plateType,
      amount,
      ...options,
    });

    const data = handleResponseData(response);
    if (data === null) {
      history.push(errorPath, { [transactionErrorKey]: 'vhc' });
      return;
    }

    const { redirectUrl } = data;
    window.location.href = redirectUrl;
  };

  const isErrorEmptyForm = formError.includes('EMPTY_FORM');
  const isErrorInvalidEmail = formError.includes('INVALID_EMAIL');

  return (
    <>
      <SectionPage>
        <Styled.FormWrapper>
          <EmailInput
            id='emailInput'
            name='email'
            label='Email the report to this address'
            value={emailAddress}
            onChange={handleOnChangeEmail}
            hasError={Boolean(formError.length)}
            errorMessage={
              isErrorInvalidEmail
                ? formValidationErrorsData['INVALID_EMAIL']
                : ''
            }
          />

          <FormCheckbox
            id='noSendEmail'
            data-testid='noSendEmailCheckbox'
            name='confirm no email'
            label={
              <span>
                I do <strong>not</strong> wish to receive my report via email
              </span>
            }
            value='accept'
            onChange={handleOnChangeCheckbox}
            checked={noSendEmail}
            hasError={isErrorEmptyForm}
          />

          <EmptyFormError
            visible={isErrorEmptyForm}
            errorMessage={formValidationErrorsData['EMPTY_FORM']}
          />
          <NoEmailWarning visible={noSendEmail} />

          <Styled.ButtonContainer>
            <Button onClick={handleSubmitForm}>Continue to payment</Button>
            <Button variant='secondary' onClick={navigateToSearch}>
              Buy another report
            </Button>
          </Styled.ButtonContainer>
        </Styled.FormWrapper>
      </SectionPage>
      <EmailConfirmationModal
        emailAddress={emailAddress}
        contentName='receipt and Vehicle History Report'
        onCancel={() => setConfirmEmailModalOpen(false)}
        onConfirm={() => {
          setConfirmEmailModalOpen(false);
          handlePostRequestPayment();
        }}
        isOpen={confirmEmailModalOpen}
      />
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default VHCSubmitForm;

export const validateEmailForm = (
  email: string,
  checked: boolean
): FormValidationErrors[] => {
  const validationErrors = [] as FormValidationErrors[];

  if (email === '' && checked === false) {
    validationErrors.push('EMPTY_FORM');
    return validationErrors;
  }

  validateEmail(email)
    .filter((error) => error !== 'EMPTY_FIELD')
    .forEach((error) => validationErrors.push(error));

  return validationErrors;
};

type FormValidationErrors = 'EMPTY_FORM' | EmailValidationErrors;

const formValidationErrorsData: { [key in FormValidationErrors]: string } = {
  ...emailValidationErrorsData,
  EMPTY_FORM:
    'Please enter an email address or select the checkbox to proceed.',
};
