import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SectionPage } from '@snsw-gel/react';
import { useStoreSelector } from 'store';
import DownloadReportModal from './DownloadReportModal/DownloadReportModal';
import * as Styled from './RedirectToSearch.styled';

const RedirectToSearch: React.FC = () => {
  const history = useHistory();
  const hasReceivedReport = useStoreSelector(
    (state) => state.confirmation.hasReceivedReport
  );
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const handleRedirectFindVehicle = () => {
    if (hasReceivedReport) {
      history.push('/vhc');
    } else {
      setShowDownloadModal(true);
    }
  };

  return (
    <>
      <SectionPage>
        <Styled.ButtonContainer>
          <Button variant='secondary' onClick={handleRedirectFindVehicle}>
            Buy another report
          </Button>
        </Styled.ButtonContainer>
      </SectionPage>
      {showDownloadModal && (
        <DownloadReportModal onCloseModal={() => setShowDownloadModal(false)} />
      )}
    </>
  );
};

export default RedirectToSearch;
