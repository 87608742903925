export const transactionName = 'CR';
export const vhcReportFileName = 'VehicleHistoryReport.pdf';
export const receiptFileName = 'receipt.pdf';

export const transactionErrorKey = 'transaction';
export const drupalPageLocation = {
  frc: 'https://www.service.nsw.gov.au/transaction/check-a-vehicle-registration',
  vhc: 'https://www.service.nsw.gov.au/transaction/order-vehicle-history-report',
  renew_rego:
    'https://www.service.nsw.gov.au/transaction/renew-vehicle-registration',
};
export const FRC_TUTD_APP_NAME = 'free-registration-check';
export const VHC_TUTD_APP_NAME = 'vehicle-history-report';
export const unregistered_vehicle_link =
  'https://www.nsw.gov.au/driving-boating-and-transport/vehicle-registration/unregistered-vehicles';

export const mainElementId = 'main-content';
