import React from 'react';
import { Button } from '@snsw-gel/react';
import * as StyledVehicleDetailsCard from '../VehicleDetailsCard.styled';

const ActionSelectVehicle: React.FC<ActionSelectVehicleProps> = ({
  label,
  onClickEvent,
}) => (
  <StyledVehicleDetailsCard.ActionContainer alignItem='center'>
    <Button onClick={onClickEvent}>{label}</Button>
  </StyledVehicleDetailsCard.ActionContainer>
);

export default ActionSelectVehicle;

interface ActionSelectVehicleProps {
  label: string;
  onClickEvent: () => Promise<void> | void;
}
