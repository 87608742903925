const jestMockedReCaptchaReturnToken = 'jest-recaptcha-token';

/**
 * we are importing the Google reCAPTCHA (grecaptcha) via the script tag in static HTMl and can't mock the functions
 * use the following function to mock grecaptcha in the `setupTest.js` file:
 *
 * import { GoogleRecaptchaHelpers } from '@rmstransactions/components';
 * global.grecaptcha = GoogleRecaptchaHelpers.jestMockSetupGoogleReCaptcha;
 */
const jestMockSetupGoogleReCaptcha = {
  enterprise: {
    ready: (callback: Function) => {
      return callback();
    },
    render: () => 0,
    reset: () => null,
    execute: () => jestMockedReCaptchaReturnToken,
    getResponse: () => jestMockedReCaptchaReturnToken,
  },
};

// use this function in jest tests to return expected headers with ReCAPTCHA headers
const constructExpectedReturnHeaders = (
  action: string,
  isChallengeType = false
) => ({
  'x-recaptcha-action': action,
  'x-recaptcha-token': jestMockedReCaptchaReturnToken,
  'x-recaptcha-type': isChallengeType ? 'challenge' : 'silent',
});

const expectedRecaptchaStatusCode = 401;
const expectedRecaptchaErrorCode = 'RecaptchaScoreTooLow';
const isErrorLowRecaptchaScore = (res: any) => {
  return (
    res.response?.status === expectedRecaptchaStatusCode &&
    res.response?.data?.code === expectedRecaptchaErrorCode
  );
};

const GoogleReCaptchaHelpers = {
  jestMockSetupGoogleReCaptcha,
  constructExpectedReturnHeaders,
  isErrorLowRecaptchaScore,
};

export default GoogleReCaptchaHelpers;
