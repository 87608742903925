import React from 'react';
import { BrandIconReportsDocs } from '@snsw-gel/react';
import ReviewInfoContent from 'components/ReviewInfoContent/ReviewInfoContent';
import aboutTheReportMarkdown from './aboutTheReport.md';
import {
  formattedUnorderedListMobile,
  hyperlinkDownload,
} from 'utils/providers/markdownRenderer';

const AboutTheReport: React.FC = () => (
  <ReviewInfoContent
    title='About the vehicle history report'
    Icon={BrandIconReportsDocs}
    markdown={aboutTheReportMarkdown}
    markdownRenderSettings={{
      ...hyperlinkDownload,
      ...formattedUnorderedListMobile,
    }}
  />
);

export default AboutTheReport;
