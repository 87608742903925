import styled, { css } from 'styled-components';
import { Heading, tokens } from '@snsw-gel/react';

interface CardContainerProps {
  border?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;

  &:only-child {
    margin: 0;
  }

  ${({ border }) =>
    border &&
    css`
      padding: 1rem;
      border: 0.125rem solid ${tokens.colors.grey.mystic};
      border-radius: 0.5rem;
    `}
`;

export const MainContentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    flex-direction: row;
  }
`;

export const VehicleDetailsContainer = styled.div<VehicleDetailsContainerProps>`
  flex-grow: 1;

  ${({ skeleton }) =>
    skeleton &&
    css`
      max-width: 24rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    `};
`;

interface VehicleDetailsContainerProps {
  skeleton?: boolean;
}

export const HeadingDetails = styled(Heading)`
  && {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
`;

interface ParagraphDetailsProps {
  uppercase?: boolean;
}

export const ParagraphDetails = styled.p<ParagraphDetailsProps>`
  margin: 0;
  line-height: 1.5rem;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

interface ActionContainerProps {
  alignItem?: string;
}

export const ActionContainer = styled.div<ActionContainerProps>`
  display: flex;
  align-items: ${({ alignItem }) => alignItem}}
  margin-top: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    margin-top: unset;
  }
`;

export const SkeletonPlateNumberWrapper = styled.div`
  margin-bottom: 0.5rem;
`;
