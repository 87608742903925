import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { stepFindVehiclePage } from 'config/steps';
import Header from 'components/Header/Header';
import SilentLogin from 'components/SilentLogin/SilentLogin';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import { RoutePathParams, errorPath } from 'utils/route/urlParams';
import {
  isHostFreeRegoCheckApp,
  redirectToCheckRegistrationFRCApp,
} from 'utils/route/freeRegoCheckHostHelpers';
import { isValidTransactionType } from 'utils/route/isValidTransactionType';
import PlateNumberForm from './PlateNumberForm/PlateNumberForm';

const FindVehiclePage: React.FC = () => {
  const { transactionType } = useParams<RoutePathParams>();

  if (isHostFreeRegoCheckApp()) {
    redirectToCheckRegistrationFRCApp();
    return <></>;
  }

  if (!isValidTransactionType(transactionType)) {
    return <Redirect to={errorPath} />;
  }

  return (
    <>
      <Header />
      <PageContentWrapper stepName={stepFindVehiclePage}>
        <PlateNumberForm />
      </PageContentWrapper>
      <SilentLogin />
    </>
  );
};

export default FindVehiclePage;
