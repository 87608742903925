import React from 'react';
import ReactMarkdown from 'react-markdown';
import { InPageAlert } from '@snsw-gel/react';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import downloadErrorMessage from './downloadErrorMessage.md';

const FailedDownloadReportError: React.FC = () => {
  return (
    <InPageAlert variant='error' title='Download failed' role='alert'>
      <ReactMarkdown components={notificationLinks}>
        {downloadErrorMessage}
      </ReactMarkdown>
    </InPageAlert>
  );
};

export default FailedDownloadReportError;
