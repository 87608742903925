import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ComponentLoader } from '@snsw-gel/react';
import {
  UserSession,
  UserToken,
  AnalyticsSessionManager,
  analyticsServiceV2,
} from '@rmstransactions/components';
import { useStoreDispatch } from 'store';
import { transactionErrorKey, transactionName } from 'config/constants';
import { getUserType } from 'api/api';
import {
  handleResponseData,
  handleResponseHeaders,
} from 'utils/api/httpClient';
import {
  errorPath,
  RoutePathParams,
  TransactionTypeParams,
} from 'utils/route/urlParams';
import redirectedTransaction from 'utils/route/redirectedTransaction';
import pushAnalyticsNewPage from 'utils/session/pushAnalyticsNewPage';
import transactionCleanupEvents from 'utils/session/transactionCleanupEvents';

export const silentAuthUrl = `${process.env.REACT_APP_API_HOST}/silentLogin?state=${transactionName}`;
const userTokenKey = UserToken.getStorageKey();

const SilentLogin: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const { transactionType } = useParams<RoutePathParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const urlSearchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const handleErrorRedirect = () => {
      // redirect to error page
      history.push(errorPath, {
        [transactionErrorKey]: transactionType,
      });
    };

    const handleAndSetUserType = async () => {
      // get userToken
      const response = await getUserType();
      const headers = handleResponseHeaders(response);
      const data = handleResponseData(response);
      if (headers === null || headers[userTokenKey] === undefined || !data) {
        handleErrorRedirect();
        return;
      }

      // set X-Token
      UserToken.setValue(headers[userTokenKey]);

      // setup Google analytics
      AnalyticsSessionManager.setSessionID(data.sessionId);
      if (data.myAccountId) {
        AnalyticsSessionManager.setUserID(data.myAccountId);
      }
      const analyticsUserLoginType =
        analyticsServiceV2.analyticsUserLoginMapedValues[data.type];
      AnalyticsSessionManager.setUserLoginType(analyticsUserLoginType);
      AnalyticsSessionManager.setTransactionStartTime();
      pushAnalyticsNewPage();

      setIsLoading(false);
    };

    // start silent login
    setIsLoading(true);
    const silentLoginNotCalled = !UserSession.isSilentLoginCalled();
    if (silentLoginNotCalled) {
      // redirect to silent login
      redirectedTransaction.setRedirectedTransaction(
        transactionType as TransactionTypeParams
      );
      UserSession.setSilentLoginCalled();

      // reset Google Analytics session values
      transactionCleanupEvents();
      UserSession.silentLogin(silentAuthUrl);
      return;
    }

    // silent login has being called, checking auth/anon
    UserSession.resetSilentLoginCalled();
    redirectedTransaction.resetRedirectedTransaction();

    const isGuest =
      urlSearchParams.has('isLoginRequired') &&
      urlSearchParams.get('isLoginRequired') === 'true';

    if (!isGuest && !UserSession.isLoginUser()) {
      // identified as login user but user is not logged in
      handleErrorRedirect();
      return;
    }

    if (!isGuest) {
      UserSession.setActiveSession();
    }

    handleAndSetUserType();
  }, [dispatch]);

  if (isLoading) {
    return (
      <ComponentLoader
        label='Processing, please wait. Do not refresh or navigate away.'
        fullPage
      />
    );
  }
  return null;
};

export default SilentLogin;
