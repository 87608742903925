import React from 'react';
import { TextLink } from '@snsw-gel/react';
import { Table } from '@rmstransactions/components';
import * as Styled from './VehicleTermsTable.styled';

const VehicleTermsTable: React.FC = () => {
  return (
    <Table>
      <table>
        <thead>
          <tr>
            <Styled.TableHeadCellStyling>Term</Styled.TableHeadCellStyling>
            <Styled.TableHeadCellStyling>
              Description
            </Styled.TableHeadCellStyling>
          </tr>
        </thead>
        <tbody>
          {Object.entries(vehicleDefinitions).map(([term, description]) => (
            <Styled.TableRowStyling key={term}>
              <td>{term}</td>
              <td>{description}</td>
            </Styled.TableRowStyling>
          ))}
        </tbody>
      </table>
    </Table>
  );
};

export default VehicleTermsTable;

const registrationFeeAndConcessionUrl =
  'https://www.nsw.gov.au/driving-boating-and-transport/vehicle-registration/fees-concessions-and-forms';
const conditionalRegistrationUrl =
  'https://www.nsw.gov.au/driving-boating-and-transport/vehicle-registration/conditional-and-seasonal';
const conditionCodesAndDescriptionsUrl =
  'https://www.nsw.gov.au/driving-boating-and-transport/vehicle-registration/basics/registration-and-vehicle-codes';
const nominatedConfigurationCodesUrl =
  'https://www.transport.nsw.gov.au/operations/roads-and-waterways/business-and-industry/heavy-vehicles/registering-a-heavy-vehicle-5';

const vehicleDefinitions = {
  Make: 'The name of vehicle manufacture (brand) or the company or brand that manufactures the vehicle.',
  Model: 'The particular or specific type of car made by the manufacture.',
  Colour:
    'Every vehicle has a colour number. In most cars this is usually found next to the compliance plate, in the engine bay, on the firewall, or inside the door jams.',
  Shape:
    'Shape is one of the details that is used for calculating registration fees. The shape is defined by the vehicle’s manufacturer and can be found on the RVD sheets.',
  'Manufacture year': 'Refers to the actual year when the car was built. ',
  'Tare weight':
    'This is the weight of an empty standard vehicle with all of its fluids (for example, oils and coolants) and specifically 10 litres of fuel in the tank.',
  'Gross vehicle mass':
    'Gross Vehicle Mass (GVM) is the maximum your vehicle can weigh when fully loaded as specified by the manufacturer. ',
  'Registration concession': (
    <>
      <p>
        Registration costs are a combination of admin fees and taxes or charges.
        For example, eligible pensioners do not have to pay registration fees,
        motor vehicle tax, conditional registration fees. Learn more about{' '}
        <TextLink href={registrationFeeAndConcessionUrl} isExternal>
          registration fees and concession.
        </TextLink>
      </p>
    </>
  ),
  'Condition codes': (
    <>
      <p>
        Some vehicles must comply with specific conditions to be driven on
        roads. These conditions may include where and how the vehicles are
        operated, or specific modifications.
      </p>
      <p>
        Vehicles that do not comply with Australian Design Rules and vehicle
        standards can be conditionally registered and driven on roads for
        specific functions. Learn more about{' '}
        <TextLink href={conditionalRegistrationUrl} isExternal>
          conditional registration.
        </TextLink>
      </p>
      <p>
        You must ensure the vehicle has met the conditions in order to drive it
        on NSW roads. A set of condition codes are applied to conditionally
        registered vehicles, see the full list of{' '}
        <TextLink href={conditionCodesAndDescriptionsUrl} isExternal>
          condition codes and descriptions.
        </TextLink>
      </p>
    </>
  ),
  'Configuration code': (
    <>
      <p>
        The Nominated Configuration Code describes the configuration of heavy
        vehicles. For example, the nominated configuration for trucks and prime
        movers refer to the combination of the hauling unit and trailers.
      </p>
      <p>
        &apos;Nominated configuration codes&apos; is a legal declaration by a
        vehicle&apos;s registered operator stating what the components of the
        vehicle are, and how they are used. Learn more about{' '}
        <TextLink href={nominatedConfigurationCodesUrl} isExternal>
          Nominated Configuration Codes.
        </TextLink>
      </p>
    </>
  ),
  'Odometer readings': (
    <>
      Refers to the number displayed on a vehicle&apos;s odometer. It shows how
      many kilometres a vehicle has travelled and is usually found on the
      dashboard in front of the steering wheel.
    </>
  ),
};
