import { transactionErrorKey } from 'config/constants';
import { isValidTransactionType } from './isValidTransactionType';
import { errorHistoryState, TransactionTypeParams } from './urlParams';

const getTransactionTypeFromHistoryState = (
  state: errorHistoryState
): null | TransactionTypeParams => {
  if (state && transactionErrorKey in state) {
    const transactionType: string = state[transactionErrorKey];
    if (isValidTransactionType(transactionType)) {
      return transactionType as TransactionTypeParams;
    }
  }
  return null;
};

export default getTransactionTypeFromHistoryState;
