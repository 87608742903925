import React from 'react';
import { SectionPage } from '@snsw-gel/react';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import { getSelectedVHCValidationMessages } from 'utils/providers/getSelectedVHCValidation';
import getUnsuccessfulMessages from 'utils/validation/getUnsuccessfulMessages';

const ValidationDataNotification: React.FC = () => {
  const vhcValidationMessages = getSelectedVHCValidationMessages();

  if (vhcValidationMessages === null) return <></>;

  const message = getUnsuccessfulMessages(vhcValidationMessages);
  if (!message) return <></>;

  return (
    <SectionPage>
      <NotificationMessage message={message} marginBottom />
    </SectionPage>
  );
};

export default ValidationDataNotification;
