import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { VehicleDetails } from 'api/types/vehicle';
import isConditionalVehicle from 'utils/validation/isConditionalVehicle';
import { formatVehicleMakeDescription } from 'utils/providers/formatVehicleMakeDescription';
import NotificationMessage from 'components/NotificationMessage/NotificationMessage';
import VehicleDetailsCardSkeleton from './VehicleDetailsCardSkeleton';
import * as Styled from './VehicleDetailsCard.styled';

const VehicleDetailsCard: React.FC<VehicleDetailsCardProps> = ({
  vehicleDetails,
  border,
  actionComponent,
  children,
}) => {
  const [currentDate] = useState<Dayjs>(dayjs(new Date()));

  if (vehicleDetails === null)
    return (
      <VehicleDetailsCardSkeleton
        border={border}
        actionComponent={actionComponent}
      />
    );

  const {
    plate,
    vehicleModel,
    last4DigitalOfVINChassisNumber,
    registrationEndDate,
    messages,
  } = vehicleDetails;
  const { plateNumber, plateType } = plate;

  if (
    (messages && messages.length > 0) ||
    !vehicleModel ||
    !last4DigitalOfVINChassisNumber ||
    !registrationEndDate
  ) {
    return (
      <Styled.CardContainer
        id={`vehicle-${plateNumber}`}
        data-testid={`vehicle-${plateNumber}`}
        border={border}
      >
        <Styled.MainContentWrapper>
          <Styled.VehicleDetailsContainer>
            <Styled.HeadingDetails level={2}>
              {plateNumber}
            </Styled.HeadingDetails>
            <Styled.ParagraphDetails>
              Vehicle details are not available
            </Styled.ParagraphDetails>
          </Styled.VehicleDetailsContainer>
        </Styled.MainContentWrapper>
        {messages?.map((message) => (
          <NotificationMessage message={message} key={message.identifier} />
        ))}
      </Styled.CardContainer>
    );
  }

  const description = getInformationFromData(vehicleDetails);
  const vinChassisLabel = `${
    isConditionalVehicle(vehicleDetails)
      ? 'VIN/Chassis/Serial No'
      : 'VIN/Chassis'
  }: ****`;
  const registrationDate = dayjs(registrationEndDate, 'YYYY-MM-DD');
  const isRegistrationExpired = registrationDate.diff(currentDate, 'day') <= 0;

  return (
    <Styled.CardContainer
      id={`vehicle-${plateNumber}-${plateType}`}
      data-testid={`vehicle-${plateNumber}-${plateType}`}
      border={border}
    >
      <Styled.MainContentWrapper>
        <Styled.VehicleDetailsContainer>
          <Styled.HeadingDetails level={2}>{plateNumber}</Styled.HeadingDetails>
          <Styled.ParagraphDetails uppercase>
            {description}
          </Styled.ParagraphDetails>
          <Styled.ParagraphDetails>
            {vinChassisLabel}
            {last4DigitalOfVINChassisNumber}
          </Styled.ParagraphDetails>
          <Styled.ParagraphDetails>
            <strong>
              Registration {isRegistrationExpired ? 'expired' : 'expires'}:{' '}
              {registrationDate.format('D MMMM YYYY')}
            </strong>
          </Styled.ParagraphDetails>
        </Styled.VehicleDetailsContainer>
        {actionComponent}
      </Styled.MainContentWrapper>
      {children}
    </Styled.CardContainer>
  );
};

export default VehicleDetailsCard;

interface VehicleDetailsCardProps {
  vehicleDetails: VehicleDetails | null;
  border?: boolean;
  actionComponent?: JSX.Element;
}

const cleanAndJoinText = (
  texts: Array<string | undefined>,
  separator: string
) => {
  return texts.filter((t) => t).join(separator);
};

const getInformationFromData = (vehicleData: VehicleDetails): string => {
  const manufacturerDescription = vehicleData.vehicleModel?.manufacturer
    ?.description
    ? formatVehicleMakeDescription(
        vehicleData.vehicleModel.manufacturer.description
      )
    : undefined;
  const build = cleanAndJoinText(
    [
      manufacturerDescription,
      vehicleData.vehicleModel?.model.description,
      vehicleData.vehicleModel?.bodyShape.description,
    ],
    ' '
  );

  const color = vehicleData.vehicleColour?.description;
  const manufactureYear = vehicleData.vehicleModel?.manufactureYear;
  const description = cleanAndJoinText([build, color, manufactureYear], ' - ');
  return description;
};
