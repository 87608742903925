import { AnalyticsSessionManager } from '@rmstransactions/components';
import sessionReceiptUUID from './sessionReceiptUUID';

const transactionCleanupEvents = (): void => {
  // Google Analytics
  AnalyticsSessionManager.clearSessionID();
  AnalyticsSessionManager.clearUserID();
  AnalyticsSessionManager.clearUserLoginType();
  AnalyticsSessionManager.clearTransactionStartTime();

  // clear receiptUUID stored
  sessionReceiptUUID.clear();
};

export default transactionCleanupEvents;
