import { DriveMessageDetail } from 'api/types/drives';
import { VHCValidationResponseDetails } from 'api/types/response';
import { Plate, VehicleDetails } from 'api/types/vehicle';

const vhcVehicleSessionKey = 'vhcVehicleData';

export interface VehicleSessionData {
  vehicleDetails: VehicleDetails[];
  vhcValidations: VHCValidationResponseDetails[];
  messages: DriveMessageDetail[];
  selectedVehicle: Plate;
  isConditionalVehicle: boolean;
}

const vhcVehicleSession = {
  hasData: (): boolean => {
    return Boolean(window.sessionStorage.getItem(vhcVehicleSessionKey));
  },
  getData: (): VehicleSessionData => {
    const dataString = window.sessionStorage.getItem(
      vhcVehicleSessionKey
    ) as string;
    return JSON.parse(dataString);
  },
  setData: (data: VehicleSessionData): void =>
    window.sessionStorage.setItem(vhcVehicleSessionKey, JSON.stringify(data)),
  clearData: (): void => window.sessionStorage.removeItem(vhcVehicleSessionKey),
};

export default vhcVehicleSession;
