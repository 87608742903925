import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VHCConfirmationResponse } from 'api/types/response';

export interface ConfirmationState {
  data: VHCConfirmationResponse | null;
  hasReceivedReport: boolean;
}

export const initialState: ConfirmationState = {
  data: null,
  hasReceivedReport: false,
};

export const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationData: (
      state,
      action: PayloadAction<VHCConfirmationResponse>
    ) => {
      state.data = action.payload;
      state.hasReceivedReport = Boolean(action.payload.emailAddress);
    },
    setHasReceivedReport: (state, action: PayloadAction<boolean>) => {
      state.hasReceivedReport = action.payload;
    },
    resetConfirmationData: () => initialState,
  },
});

export const confirmationSliceActions = confirmationSlice.actions;
export default confirmationSlice.reducer;
