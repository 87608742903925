import { DriveMessageDetail } from 'api/types/drives';
import errorDescriptionVHCConditionalVehicle from './errorDescriptionVHCConditionalVehicle.md';
import errorDescriptionSystemFailure from './errorDescriptionSystemFailure.md';

export const vhcConditionalVehicleError: DriveMessageDetail = {
  identifier: 'PLATE_CONDITIONALLY_REGISTERED',
  classification: 'BUSINESS',
  severity: 'INFORMATION',
  messageTitle: 'This vehicle is conditionally registered',
  messageDescription: errorDescriptionVHCConditionalVehicle,
  message: '',
};

export const systemFailureError: DriveMessageDetail = {
  identifier: 'SYSTEM_FAILURE',
  classification: 'SYSTEM',
  severity: 'ERROR',
  messageTitle: 'Our system is temporarily unavailable',
  messageDescription: errorDescriptionSystemFailure,
  message: '',
};
