import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreDispatch, useStoreSelector } from 'store';
import { vehicleSliceActions } from 'store/slice/vehicle';
import { getVehicleDetails } from 'api/api';
import { Plate } from 'api/types/vehicle';
import { transactionErrorKey } from 'config/constants';
import { stepVHCReviewPage } from 'config/steps';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import ReviewVehicleDetails from 'components/ReviewVehicleDetails/ReviewVehicleDetails';
import HorizontalRule from 'components/HorizontalRule/HorizontalRule';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { handleResponseData } from 'utils/api/httpClient';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import {
  getSelectedVHCValidationMessages,
  getSelectedVHCValidationResponse,
} from 'utils/providers/getSelectedVHCValidation';
import vhcVehicleSession from 'utils/session/vhcVehicleData';
import { errorPath } from 'utils/route/urlParams';
import getUnsuccessfulMessages from 'utils/validation/getUnsuccessfulMessages';
import WrittenOffInfo from './WrittenOffInfo/WrittenOffInfo';
import VHCSubmitForm from './EmailForm/VHCSubmitForm';
import AboutTheReport from './AboutTheReport/AboutTheReport';
import ImportantInformation from './ImportantInformation/InformationInformation';
import PurchaseCostDetails from './PurchaseCostDetails/PurchaseCostDetails';
import ValidationDataNotification from './ValidationDataNotification/ValidationDataNotification';
import RedirectActions from './RedirectActions/RedirectActions';

const VehicleHistoryCheck: React.FC = () => {
  const dispatch = useStoreDispatch();
  const history = useHistory();
  const selectedVehicle = useStoreSelector(
    (state) => state.vehicle.selectedVehicle
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const selectedVehicleDetails = getSelectedVehicleDetails();
  const selectedVHCValidationResponse = getSelectedVHCValidationResponse();
  const selectedVHCValidationMessages = getSelectedVHCValidationMessages();

  useEffect(() => {
    const navigateToVHCFindVehicle = () => {
      history.push('/vhc');
    };

    const fetchValidationData = async (plate: Plate) => {
      setIsLoading(true);
      const { plateNumber, plateType } = plate;
      if (!plateType) {
        navigateToVHCFindVehicle();
        return;
      }
      const response = await getVehicleDetails(plateNumber, plateType);
      const data = handleResponseData(response);
      if (data === null) {
        history.push(errorPath, { [transactionErrorKey]: 'vhc' });
        return;
      }

      try {
        dispatch(vehicleSliceActions.setVehicleDetails(data));
        setIsLoading(false);
      } catch {
        history.push(errorPath, { [transactionErrorKey]: 'vhc' });
      }
    };

    const storeHasRequiredData =
      selectedVehicleDetails &&
      selectedVHCValidationResponse &&
      selectedVehicle;
    if (storeHasRequiredData) return;

    if (selectedVHCValidationResponse === null && selectedVehicle) {
      fetchValidationData(selectedVehicle);
      return;
    }

    const noSavedSessionData = vhcVehicleSession.hasData() === false;
    if (noSavedSessionData) {
      navigateToVHCFindVehicle();
      return;
    }

    try {
      dispatch(vehicleSliceActions.loadVehicleStoreFromSessionStorage());
    } catch {
      navigateToVHCFindVehicle();
    }
  }, [
    selectedVehicleDetails,
    selectedVHCValidationResponse,
    selectedVehicle,
    dispatch,
  ]);

  const canBuyVHCReport = selectedVHCValidationMessages
    ? !getUnsuccessfulMessages(selectedVHCValidationMessages)
    : null;

  return (
    <>
      <PageContentWrapper stepName={stepVHCReviewPage}>
        <ValidationDataNotification />
        <ReviewVehicleDetails />
        <HorizontalRule />
        <WrittenOffInfo />
        <AboutTheReport />
        <ImportantInformation />
        <HorizontalRule marginBottom='1rem' />
        <PurchaseCostDetails />
        <HorizontalRule marginTop='1rem' />
        {canBuyVHCReport ? <VHCSubmitForm /> : <RedirectActions />}
      </PageContentWrapper>
      <LoadingOverlay visible={isLoading} />
    </>
  );
};

export default VehicleHistoryCheck;
