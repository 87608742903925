import React from 'react';
import { ContentContainer } from '@snsw-gel/react';
import { GoogleReCaptchaDisclaimer } from '@rmstransactions/components';
import SetDocumentTitle from './SetDocumentTitle/SetDocumentTitle';
import TimeoutHandler from './TimeoutHandler/TimeoutHandler';
import * as Styled from './PageContentWrapper.styled';

const PageContentWrapper: React.FC<PageContentWrapperProps> = ({
  stepName,
  children,
}) => {
  return (
    <>
      <ContentContainer>
        <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
      </ContentContainer>
      <GoogleReCaptchaDisclaimer />
      <TimeoutHandler />
      {stepName && <SetDocumentTitle stepName={stepName} />}
    </>
  );
};

export default PageContentWrapper;

interface PageContentWrapperProps {
  stepName?: string;
}
