import styled from 'styled-components';
import {
  Heading,
  FormCheckbox as SNSWFormCheckbox,
  tokens,
} from '@snsw-gel/react';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    align-items: start;
  }
`;

export const FormHeading = styled(Heading)`
  && {
    margin: 0;
  }
`;

export const PlateNumberContainer = styled.div`
  max-width: 36rem;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

export const FormCheckbox = styled(SNSWFormCheckbox)`
  margin-top: 0;
`;

export const TermsDescription = styled.p`
  margin-top: 0;
`;

export const TermsLink = styled.a`
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    width: initial;
    flex-direction: row;
    gap: 1.5rem;
  }
`;
