import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Heading } from '@snsw-gel/react';
import { AccordionItem } from '@snsw-gel/react';
import { hyperlinkNewPageExternal } from 'utils/providers/markdownRenderer';
import * as Styled from './BeforeBuyingAccordion.styled';
import disclaimer from './disclaimer.md';
import howToUseThisInfo from './howToUseThisInfo.md';
import vehicleBuyingTips from './vehicleBuyingTips.md';

const BeforeBuyingAccordion: React.FC = () => {
  return (
    <>
      <Heading level={2}>Before buying a vehicle</Heading>
      <Styled.Accordian id='beforeBuyingVehicle'>
        <AccordionItem title='Vehicle buying tips' headingElement='h3'>
          <Styled.AccordionWrapper>
            <ReactMarkdown components={hyperlinkNewPageExternal}>
              {vehicleBuyingTips}
            </ReactMarkdown>
          </Styled.AccordionWrapper>
        </AccordionItem>
        <AccordionItem title='How to use this info' headingElement='h3'>
          <Styled.AccordionWrapper>
            <ReactMarkdown>{howToUseThisInfo}</ReactMarkdown>
          </Styled.AccordionWrapper>
        </AccordionItem>
        <AccordionItem title='Disclaimer' headingElement='h3'>
          <Styled.AccordionWrapper>
            <ReactMarkdown>{disclaimer}</ReactMarkdown>
          </Styled.AccordionWrapper>
        </AccordionItem>
      </Styled.Accordian>
    </>
  );
};

export default BeforeBuyingAccordion;
