import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const FormWrapper = styled.div`
  margin-top: 2.5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    flex-direction: row;
    gap: 1.5rem;
    margin-top: 3rem;
  }
`;
