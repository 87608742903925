import styled from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const skeletonTextWidth = 160;

export const SkeletonTransactionWrapper = styled.div`
  width: ${skeletonTextWidth}px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: ${tokens.breakpoints.md}px) {
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    padding-top: 2rem;
  }
`;
