import React from 'react';
import ReactMarkdown from 'react-markdown';
import { SectionPage } from '@snsw-gel/react';
import { registrationRestrictionCodes } from 'config/conditionCodes';
import getSelectedVehicleDetails from 'utils/providers/getSelectedVehicleDetails';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import { StyledInPageAlert } from './RegistrationRestriction.styled';
import registrationRestrictionMarkdown from './registrationRestriction.md';

const RegistrationRestriction: React.FC = () => {
  const vehicleDetails = getSelectedVehicleDetails();

  const registrationConditions =
    vehicleDetails?.conditions?.registrationCondition;
  const hasRegistrationConditions =
    registrationConditions && registrationConditions.length > 0;
  if (!hasRegistrationConditions) return <></>;

  const hasSelectedConditionCodes = registrationConditions?.find(
    (registrationCondition) =>
      selectedConditionCodes.includes(
        registrationCondition.codeCategory + registrationCondition.code
      )
  );
  if (!hasSelectedConditionCodes) return <></>;

  return (
    <SectionPage>
      <StyledInPageAlert variant='info' title='Registration restriction'>
        <ReactMarkdown components={notificationLinks}>
          {registrationRestrictionMarkdown}
        </ReactMarkdown>
      </StyledInPageAlert>
    </SectionPage>
  );
};

export default RegistrationRestriction;

const selectedConditionCodes = Object.keys(registrationRestrictionCodes);
