import React from 'react';
import { Modal } from '@snsw-gel/react';

// Copied from common components to update to GEL 3
const EmailConfirmationModal: React.FC<EmailConfirmationModalProps> = ({
  emailAddress,
  contentName = 'receipt',
  onConfirm,
  onCancel,
  isOpen,
}) => {
  return (
    <>
      {isOpen && (
        <Modal
          id='email-confirmation-modal'
          title={modalTitle}
          buttons={[
            { text: onConfirmButtonLabel, onClick: onConfirm },
            { text: onCancelButtonLabel, onClick: onCancel },
          ]}
        >
          {modalDescription(contentName)} <strong>{emailAddress}</strong>
        </Modal>
      )}
    </>
  );
};

interface EmailConfirmationModalProps {
  emailAddress: string;
  contentName?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

export const modalTitle = 'Is this email address correct?';
export const modalDescription = (content: string): string =>
  `For your security, please confirm you’d like your ${content} sent to`;
export const onConfirmButtonLabel = 'Send';
export const onCancelButtonLabel = 'Cancel';

export default EmailConfirmationModal;
