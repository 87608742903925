export type Spacing =
  | 'none'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl';

// SNSW token.spacing converted to REM measurments
export const spacing: Record<Spacing, string> = {
  none: 'none',
  xs: '0.5rem',
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem',
  xl: '2.5rem',
  xxl: '3rem',
  xxxl: '3.5rem',
};

export default spacing;
