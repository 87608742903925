import React from 'react';
import { InlineError } from '@snsw-gel/react';
import * as Styled from './EmptyFormError.styled';

const EmptyFormError: React.FC<EmptyFormErrorProps> = ({
  visible,
  errorMessage,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <Styled.EmptyFormErrorWrapper>
      <InlineError>{errorMessage}</InlineError>
    </Styled.EmptyFormErrorWrapper>
  );
};

export default EmptyFormError;

interface EmptyFormErrorProps {
  visible: boolean;
  errorMessage: string;
}
