const challengeWidgetIDLabel = 'data-recaptcha-widget-id';

const recaptchaChallengeNotificationError = {
  identifier: 'RECAPTCHA_SCORE',
  classification: 'BUSINESS',
  severity: 'ERROR',
  messageTitle: 'Please complete the reCAPTCHA',
  messageDescription:
    'An unexpected error occurred. Please complete the reCAPTCHA and try again.',
  message: '',
};

const getWidgetID = (containerID: string | null): number | null => {
  if (!containerID) {
    return null;
  }

  const challengeWrapperElement = document.getElementById(containerID);
  if (!challengeWrapperElement) return null;

  if (!challengeWrapperElement.hasAttribute(challengeWidgetIDLabel)) {
    return null;
  }

  return parseInt(
    challengeWrapperElement.getAttribute(challengeWidgetIDLabel) as string
  );
};

const getChallengeToken = (widgetID: number): string => {
  return grecaptcha.enterprise.getResponse(widgetID);
};

const getTokenUsingContainerID = (containerID: string | null): string => {
  const widgetID = getWidgetID(containerID);
  if (widgetID === null) {
    return '';
  }
  return getChallengeToken(widgetID);
};

const ChallengeReCaptchaHelpers = {
  challengeWidgetIDLabel,
  recaptchaChallengeNotificationError,
  getWidgetID,
  getChallengeToken,
  getTokenUsingContainerID,
};

export default ChallengeReCaptchaHelpers;
