import React from 'react';
import { SectionPage } from '@snsw-gel/react';
import {
  getSelectedVHCValidationMessages,
  getSelectedVHCValidationResponse,
} from 'utils/providers/getSelectedVHCValidation';
import PurchaseCostDetailsSkeleton from './PurchaseCostDetailsSkeleton';
import * as Styled from './PurchaseCostDetails.styled';

const PurchaseCostDetails: React.FC = () => {
  const vhcValidationResponse = getSelectedVHCValidationResponse();
  const vhcValidationMessages = getSelectedVHCValidationMessages();

  if (vhcValidationResponse === null || vhcValidationMessages === null) {
    return <PurchaseCostDetailsSkeleton />;
  }

  if (!vhcValidationResponse.vhcPrice) return <></>;

  const { netAmount, gstAmount, grossAmount } = vhcValidationResponse.vhcPrice;

  return (
    <>
      <SectionPage>
        <Styled.CostList>
          <Styled.CostItem>
            <span>Vehicle history report</span>
            <span data-testid='net-amount'>
              ${formatDollarString(netAmount)}
            </span>
          </Styled.CostItem>
          <Styled.CostItem>
            <span>GST</span>
            <span data-testid='gst-amount'>
              ${formatDollarString(gstAmount)}
            </span>
          </Styled.CostItem>
          <Styled.CostItem bold>
            <span>Total report cost</span>
            <span data-testid='gross-amount'>
              ${formatDollarString(grossAmount)}
            </span>
          </Styled.CostItem>
        </Styled.CostList>
      </SectionPage>
    </>
  );
};

export default PurchaseCostDetails;

const formatDollarString = (amount: string) => {
  const floatAmount = parseFloat(amount);
  return floatAmount.toFixed(2);
};
