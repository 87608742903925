import React from 'react';
import { MoreInfoModal } from '@snsw-gel/react';
import * as Styled from '../../VehicleInformation.styled';

const OdometerDetailsLearnMore: React.FC = () => {
  return (
    <Styled.OdometerDetailsLearnMore>
      <MoreInfoModal
        hyperLinKText='Learn more'
        questionHyperLink
        helpText={<OdometerDetailsLearnMoreText />}
        title='Odometer readings'
      />
    </Styled.OdometerDetailsLearnMore>
  );
};
const OdometerDetailsLearnMoreText: React.FC = () => {
  return (
    <>
      <p>
        Transport for NSW makes no warranty or guarantee as to the accuracy of
        odometer readings. The most recent odometer readings provided to
        Transport for NSW, usually by third parties as a result of previous
        registration inspections are recorded here, however Transport for NSW
        cannot verify their accuracy. A maximum of three readings will be
        displayed. It is recommended that customers view the vehicle’s log book
        and service history and make their own enquiries as to the vehicle’s
        odometer reading. If the fields are blank, there have been no readings
        provided to Transport for NSW.
      </p>
      <p>
        If you suspect odometer tampering or fraud has occurred, please refer to
        the{' '}
        <a
          href='https://www.service.nsw.gov.au/guide/buying-vehicle'
          target='_blank'
          rel='noopener noreferrer'
        >
          Buying a vehicle
        </a>{' '}
        guide.
      </p>
    </>
  );
};

export default OdometerDetailsLearnMore;
