import React from 'react';
import { Redirect } from 'react-router-dom';
import redirectedTransaction from 'utils/route/redirectedTransaction';
import {
  isHostFreeRegoCheckApp,
  redirectToCheckRegistrationFRCApp,
} from 'utils/route/freeRegoCheckHostHelpers';

const SilentLoginRedirectPage: React.FC = () => {
  if (isHostFreeRegoCheckApp()) {
    redirectToCheckRegistrationFRCApp();
    return <></>;
  }

  const redirectedPath = redirectedTransaction.getRedirectedTransaction();
  const redirectUrl = getRedirectUrl(redirectedPath);
  const queryParameters = window.location.search;

  return (
    <Redirect
      to={{
        pathname: `${redirectUrl}`,
        search: queryParameters,
      }}
    />
  );
};

export default SilentLoginRedirectPage;

const getRedirectUrl = (redirect: string | null) => {
  return redirect === 'vhc' ? '/vhc' : '/frc';
};
