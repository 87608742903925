import React from 'react';
import dayjs from 'dayjs';
import { TextLink } from '@snsw-gel/react';
import { unregistered_vehicle_link } from 'config/constants';
import { VehicleDetails } from 'api/types/vehicle';

const getCurrentDate = () => {
  return dayjs().hour(0).minute(0).second(0);
};

const parseDate = (date: string) => {
  const createDate = dayjs(date, 'YYYY-MM-DD');
  if (!createDate.isValid()) return null;
  createDate.hour(0).minute(0).second(0);
  return createDate;
};

export interface RegistrationMessage {
  registrationStatusMessage: string;
  isRegistrationExpired: boolean;
}

export const GetRegistrationMessage: React.FC<RegistrationMessage> = ({
  registrationStatusMessage,
  isRegistrationExpired,
}) => {
  if (isRegistrationExpired && registrationStatusMessage === 'active') {
    return (
      <p>
        If you don&apos;t renew your rego by the due date your vehicle will be{' '}
        <TextLink href={unregistered_vehicle_link} isExternal>
          unregistered
        </TextLink>
        . Driving an unregistered vehicle is illegal. It carries heavy penalties
        and can mean your vehicle is not covered by CTP insurance.
      </p>
    );
  } else {
    return (
      <p>
        If your vehicle registration is {registrationStatusMessage}, it is
        considered unregistered. Driving an unregistered vehicle is illegal and
        potentially dangerous. You can drive an{' '}
        <TextLink href={unregistered_vehicle_link} isExternal>
          unregistered vehicle
        </TextLink>{' '}
        in NSW for the purpose of obtaining registration.
      </p>
    );
  }
};

export const isRegistrationExpired = (
  vehicleDetails: VehicleDetails | null
): boolean => {
  if (!vehicleDetails) return true;

  const currentDate = getCurrentDate();
  const registrationEndDate = vehicleDetails.registrationEndDate;
  if (!registrationEndDate) {
    return true;
  }

  const registrationExpiryAsDate = parseDate(registrationEndDate);
  if (!registrationExpiryAsDate) {
    return true;
  }

  const registrationTimeLeftInDays = registrationExpiryAsDate.diff(
    currentDate,
    'days'
  );

  return registrationTimeLeftInDays < 0;
};

// has 3 months to re-register (Drives will still return registrationStatus as "Active" )
export const isRegistrationExpiredAndWithinThreeMonths = (
  vehicleDetails: VehicleDetails
): boolean => {
  const registrationEndDate = vehicleDetails.registrationEndDate;
  if (!registrationEndDate) {
    return false;
  }

  const registrationExpiryAsDate = parseDate(registrationEndDate);
  if (!registrationExpiryAsDate) {
    return false;
  }

  const currentDate = getCurrentDate();
  const timeLeftInDays = currentDate.diff(registrationExpiryAsDate, 'days');
  const timeLeftInMonths = currentDate.diff(registrationExpiryAsDate, 'months');

  // is not today and is within the 3 month duration
  if (timeLeftInMonths >= 0 && timeLeftInMonths < 3 && timeLeftInDays > 0)
    return true;

  // not expired and/or today
  return false;
};

export const getRegistrationStatusLabel = (
  vehicleDetails: VehicleDetails | null
): string => {
  if (!vehicleDetails) return 'Registered';

  const registrationStatus = vehicleDetails?.registrationStatus?.toLowerCase();
  if (registrationStatus === 'active') {
    const isExpired = isRegistrationExpired(vehicleDetails);
    if (!isExpired) {
      return 'Registered';
    }
    return 'Registration expired';
  }

  return `Registration ${registrationStatus}`;
};

export const getExpiryLabel = (
  vehicleDetails: VehicleDetails | null
): string => {
  if (!vehicleDetails) return 'Expires: ';

  return isRegistrationExpired(vehicleDetails) ? 'Expired: ' : 'Expires: ';
};

const applyPrefix = (time: number): string => (time > 1 ? 's' : '');

export const getRegistrationTimeMessage = (
  vehicleDetails: VehicleDetails | null
): string => {
  const registrationEndDate = vehicleDetails?.registrationEndDate;
  if (!registrationEndDate) return 'Not available';

  const registrationExpiryDateAsObject = parseDate(registrationEndDate);
  if (!registrationExpiryDateAsObject) return 'Not available';

  const currentDate = getCurrentDate();
  const registrationTimeLeftDays = registrationExpiryDateAsObject.diff(
    currentDate,
    'days',
    true
  );
  const timeLeftDays = Math.round(registrationTimeLeftDays);
  const timeLeftYears = registrationExpiryDateAsObject.diff(
    currentDate,
    'years'
  );
  const timeLeftMonths = registrationExpiryDateAsObject.diff(
    currentDate,
    'months'
  );

  let timeLeft = Math.abs(timeLeftMonths);
  let timeMsg = '';
  if (timeLeft < 1 && timeLeft >= 0) {
    timeLeft = Math.abs(timeLeftDays);
    timeMsg = `${timeLeft} day${applyPrefix(timeLeft)}`;
  } else if (timeLeft >= 1 && timeLeft < 12) {
    timeMsg = `Over ${timeLeft} month${applyPrefix(timeLeft)}`;
  } else if (timeLeft >= 12) {
    timeLeft = Math.abs(timeLeftYears);
    timeMsg = `Over ${timeLeft} year${applyPrefix(timeLeft)}`;
  }

  if (Math.sign(timeLeftDays) === 0) {
    timeMsg = 'today';
  } else if (Math.sign(timeLeftDays) === -1) {
    timeMsg = timeMsg + ' ago';
  } else if (Math.sign(timeLeftDays) === 1) {
    timeMsg = timeMsg + ' left';
  }

  return `${registrationExpiryDateAsObject.format('D MMMM YYYY')} (${timeMsg})`;
};

export const isExpiryToday = (
  vehicleDetails: VehicleDetails | null
): boolean => {
  if (!vehicleDetails || !vehicleDetails.registrationEndDate) return false;

  const currentDate = getCurrentDate();
  const registrationExpiryDate = parseDate(vehicleDetails.registrationEndDate);
  if (!registrationExpiryDate) return false;

  /*
   * we use the "exact" option because minusing two days starting at the same time
   * does not result in 1 day difference, it ends up being close to 1 (0.99 etc)
   * round at the end to get an estimate accurate number as we set the time to 00:00
   * */
  const registrationTimeLeftDays = registrationExpiryDate.diff(
    currentDate,
    'days',
    true
  );

  return Math.round(registrationTimeLeftDays) === 0;
};

export const isRenewLinkToBeDisplayed = (
  vehicleDetails: VehicleDetails | null
): boolean => {
  if (!vehicleDetails) return false;

  if (isExpiryToday(vehicleDetails)) {
    return true;
  }

  if (isRegistrationExpired(vehicleDetails)) {
    return true;
  }

  return false;
};

export const isMessageToBeDisplayed = (
  vehicleDetails: VehicleDetails | null
): boolean => {
  if (!vehicleDetails) return false;

  const registrationStatus = vehicleDetails.registrationStatus;
  if (
    isRegistrationExpired(vehicleDetails) ||
    registrationStatus === 'Suspended' ||
    registrationStatus === 'Cancelled'
  ) {
    return true;
  }

  return false;
};

export const isInsuranceExpired = (vehicleDetails: VehicleDetails): boolean => {
  const currentDate = getCurrentDate();
  const insuranceEndDate = vehicleDetails.policyDetails?.endDate;
  if (!insuranceEndDate) {
    return true;
  }

  const insuranceExpiryAsDate = parseDate(insuranceEndDate);
  if (!insuranceExpiryAsDate) {
    return true;
  }

  const insuranceTimeLeftInDays = insuranceExpiryAsDate.diff(
    currentDate,
    'days'
  );

  return insuranceTimeLeftInDays < 0;
};
