import React from 'react';
import { SkeletonText } from '@snsw-gel/react';
import * as Styled from './TransactionSwitch.styled';

const TransactionSwitchSkeleton: React.FC = () => {
  return (
    <Styled.SkeletonTransactionWrapper>
      <SkeletonText width={Styled.skeletonTextWidth} noMargin />
    </Styled.SkeletonTransactionWrapper>
  );
};

export default TransactionSwitchSkeleton;
