import styled, { css } from 'styled-components';
import { Heading, tokens } from '@snsw-gel/react';

interface CardContainerProps {
  border?: boolean;
}

export const CardContainer = styled.div<CardContainerProps>`
  margin-top: 3rem;
  margin-bottom: 3rem;

  ${({ border }) =>
    border &&
    css`
      padding: 2rem;
      border: 0.125rem solid ${tokens.colors.grey.mystic};
      border-radius: 0.5rem;
    `}
`;

export const MainContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
`;

export const HeadingDetails = styled(Heading)`
  && {
    margin-top: 0rem;
  }
`;

export const DescriptionDetails = styled.div`
  grid-column: 1 / span 2;

  @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    grid-column: 2 / span 2;
  }
`;

export const ParagraphDetails = styled.div`
  margin-bottom: 1rem;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: nowrap
    flex-direction: column;
    gap: 1rem;
    align-items:center;
  
    @media screen and (min-width: ${tokens.breakpoints.sm}px) {
    flex-direction: row;
  }
`;
