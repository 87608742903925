import React from 'react';
import ReactMarkdown from 'react-markdown';
import { InPageAlert } from '@snsw-gel/react';
import { notificationLinks } from 'utils/providers/markdownRenderer';
import errorMessageMarkdown from './ErrorMessage.md';

export const EmailNotificationError: React.FC<EmailNotificationErrorProps> = ({
  visible,
}) => {
  return (
    <>
      {visible && (
        <InPageAlert variant='error' title='Email failed to send'>
          <ReactMarkdown components={notificationLinks}>
            {errorMessageMarkdown}
          </ReactMarkdown>
        </InPageAlert>
      )}
    </>
  );
};

interface EmailNotificationErrorProps {
  visible: boolean;
}
