import React from 'react';
import { ComponentLoader } from '@snsw-gel/react';

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  visible,
  label = loadingOverlayLabelText,
}) => (visible ? <ComponentLoader label={label} fullPage /> : <></>);

export default LoadingOverlay;

interface LoadingOverlayProps {
  visible: boolean;
  label?: string;
}

export const loadingOverlayLabelText = 'One moment, please...';
