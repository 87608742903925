import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { RoutePathParams } from 'utils/route/urlParams';
import sessionReceiptUUID from 'utils/session/sessionReceiptUUID';

const ConfirmationRedirect: React.FC = () => {
  const history = useHistory();
  const { receiptUUID } = useParams<RoutePathParams>();

  if (receiptUUID) {
    sessionReceiptUUID.setValue(receiptUUID);
  }

  useEffect(() => {
    history.push({
      pathname: '/confirmation',
      state: {
        receiptUUID: receiptUUID,
      },
    });
  }, [receiptUUID]);

  return <></>;
};

export default ConfirmationRedirect;
