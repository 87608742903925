import React from 'react';
import { SkeletonContainer, SkeletonText } from '@snsw-gel/react';
import * as Styled from './TimestampNavigation.styled';

const TimestampNavigationSkeleton: React.FC = () => {
  return (
    <SkeletonContainer>
      <Styled.ContentWrapper skeleton>
        <SkeletonText width={192} noMargin />
      </Styled.ContentWrapper>
    </SkeletonContainer>
  );
};

export default TimestampNavigationSkeleton;
