import React from 'react';
import {
  SectionPage,
  SkeletonContainer,
  SkeletonText,
} from '@snsw-gel/react';
import * as Styled from './PaymentSummary.styled';
import { PaymentSummaryHeading } from './PaymentSummary';

const PaymentSummarySkeleton: React.FC = () => {
  return (
    <SectionPage>
      <SkeletonContainer>
        <Styled.SummaryWrapper>
          {PaymentSummaryHeading}
          <FormatedSkeletonText />
          <FormatedSkeletonText />
          <FormatedSkeletonText />
        </Styled.SummaryWrapper>
      </SkeletonContainer>
    </SectionPage>
  );
};

export default PaymentSummarySkeleton;

const FormatedSkeletonText: React.FC = () => {
  return (
    <Styled.SkeletonTextWrapper>
      <SkeletonText width={320} noMargin />
    </Styled.SkeletonTextWrapper>
  );
};
