import React, { useEffect, useRef } from 'react';
import ChallengeReCaptchaHelpers from './ChallengeReCaptchaHelpers';
import * as Styled from './ChallengeReCaptcha.style';

const { challengeWidgetIDLabel } = ChallengeReCaptchaHelpers;

const ChallengeReCaptcha: React.FC<ChallengeReCaptchaProps> = ({
  containerID,
  render,
  showNoInputError,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (render && grecaptcha) {
      grecaptcha.enterprise.ready(() => {
        const widgetID = grecaptcha.enterprise.render(containerID, {
          sitekey: process.env.REACT_APP_RECAPTCHA_CHALLENGE_SITE_KEY,
        });

        if (containerRef.current) {
          containerRef.current.setAttribute(
            challengeWidgetIDLabel,
            widgetID.toString()
          );
        }
      });
    }
  }, [render]);

  return (
    <Styled.ChallengeContainer
      id={containerID}
      ref={containerRef}
      $visible={render}
    >
      {showNoInputError && (
        <Styled.ErrorMessageContainer>
          <Styled.ErrorNotificationIcon size='sm' />
          <span>{defaultRecaptchaErrorMessage}</span>
        </Styled.ErrorMessageContainer>
      )}
    </Styled.ChallengeContainer>
  );
};

export default ChallengeReCaptcha;

const defaultRecaptchaErrorMessage = 'Please check the reCAPTCHA';

interface ChallengeReCaptchaProps {
  containerID: string;
  render: boolean;
  showNoInputError: boolean;
}
